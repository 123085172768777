import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Badge, Box, Button, Chip, CircularProgress, FormControl, Grid, InputLabel, Link, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Add, Delete, Edit, Forum, GetApp, KeyboardArrowDown, SnoozeTwoTone } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import projectTaskService from '../../services/ProjectTaskService';
import ConfirmationDialog from '../common/ConfirmationDialog';
import projectService from '../../services/ProjectService';
import taskTypeService from '../../services/TaskTypeService';
import PercentageProgressBar from '../common/PercentageProgressBar';
import vehicleService from '../../services/VehicleService';
import userService from '../../services/UserService';
import { useTitle } from '../dashboard/TitleContext';

const columns = [
    { id: 'project', subId: 'name', label: 'Projekt' },
    { id: 'subTasks', label: 'Alfeladatok Száma' },
    { id: 'priority', label: 'Prioritás' },
    { id: 'status', label: 'Státusz' },
    { id: 'description', label: 'Leírás' },
    { id: 'taskType', subId: 'name', label: 'Feladatkör' },
    { id: 'vehicles', label: 'Járművek' },
    { id: 'users', label: 'Munkavállalók' },
    { id: 'updatedAt', label: 'Módosítva' }
];

const priorities = [
    { id: "EXTRA_IMPORTANT", name: "Extra Fontos" },
    { id: "IMPORTANT", name: "Fontos" },
    { id: "NORMAL", name: "Normál" },
    { id: "NOT_IMPORTANT", name: "Nem Fontos" }
]

const statuses = [
    { id: "NOT_STARTED", name: "Nincs elkezdve" },
    { id: "IN_PROGRESS", name: "Folyamatban" },
    { id: "BLOCKED", name: "Elakadva" },
    { id: "OVERDUE", name: "Csúszásban" },
    { id: "DONE", name: "Befejezve" }
]

export default function ProjectTaskTable() {

    useTitle([
        {name: 'Projekt Feladatok', url: '/dashboard/project-tasks'}
    ])

    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem("project-tasks-rows-per-page") || 25);
    const [totalCount, setTotalCount] = React.useState(0);
    const [searchParams, setSearchParams] = React.useState(JSON.parse(localStorage.getItem("project-task-search-params")) || {})
    const [expanded, setExpanded] = React.useState(true);
    const [dialogState, setDialogState] = React.useState({ open: false, object: null });

    const handleClickOpen = (object) => {
        setDialogState({ open: true, object: object });
    };

    const handleClose = () => {
        setDialogState({ open: false, object: null });
    };

    useEffect(() => {
        retrieveList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchParams]);

    const handleExtension = () => {
        setExpanded(!expanded);
    };

    const onSearchParamsChange = (name, value) => {
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value
        }))
    }

    const clear = () => {
        setSearchParams({})
    }

    useEffect(() => {
        if (searchParams) localStorage.setItem("project-task-search-params", JSON.stringify(searchParams));
        else localStorage.removeItem("project-task-search-params")
    }, [searchParams]);


    function retrieveList() {
        setRows(null)
        projectTaskService.search(buildQueryParams(),
            (response, error) => {
                console.log(response)
                if (response) {
                    const result = response.content.map(projectTask => ({
                        ...projectTask,
                        status: statuses.find(s => s.id === projectTask.status)?.name,
                        priority: priorities.find(s => s.id === projectTask.priority)?.name
                    }));
                    setTotalCount(response.totalElements)
                    setRows(result);
                }
            }
        );
    }

    function buildQueryParams() {
        return {
            page: page,
            size: rowsPerPage,
            ...(searchParams.project && { projectId: searchParams.project.id }),
            ...(searchParams.priorities && searchParams.priorities.length > 0 && { priorities: searchParams.priorities.map(p => p.id) }),
            ...(searchParams.statuses && searchParams.statuses.length > 0 && { statuses: searchParams.statuses.map(s => s.id) }),
            ...(searchParams.description && { description: searchParams.description }),
            ...(searchParams.taskType && { taskTypeId: searchParams.taskType.id }),
            ...(searchParams.vehicle && { vehicleId: searchParams.vehicle.id }),
            ...(searchParams.users && { userIds: searchParams.users.map(user => user.id) })
        }
    }

    const [projects, setProjects] = React.useState([]);
    useEffect(() => {
        projectService.getSelectors(
            (response, error) => {
                if (response) setProjects(response);
                else console.log(error);
            },
        );
    }, []);

    const [taskTypes, setTaskTypes] = React.useState([]);
    useEffect(() => {
        taskTypeService.search(
            (response, error) => {
                if (response) setTaskTypes(response);
                else console.log(error);
            },
        );
    }, []);

    const [vehicles, setVehicles] = React.useState([]);
    useEffect(() => {
        vehicleService.search(
            (response, error) => {
                if (response) setVehicles(response);
                else {
                    console.log(error);
                }
            },
        );
    }, []);


    const [users, setUsers] = React.useState([]);
    useEffect(() => {
        userService.getSelectors(
            (response, error) => {
                if (response) setUsers(response);
                else console.log(error);
            },);
    }, []);

    const onUsersChange = (event) => {
        const { value } = event.target
        const newSelection = typeof value === 'string' ? value.split(',') : value;
        const uniqueSelection = newSelection.reduce((acc, user) => {
            const exists = acc.find(item => item.id === user.id);
            if (exists) return acc.filter(item => item.id !== user.id);
            else return [...acc, user];
        }, []);

        setSearchParams((prev) => ({
            ...prev,
            users: uniqueSelection,
        }));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = event.target.value
        setRowsPerPage(event.target.value);
        localStorage.setItem("project-tasks-rows-per-page", rowsPerPage)
        setPage(0);
    };

    const navigate = useNavigate();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
            },
        },
    };

    function getStyles(priority, priorities) {
        if (priorities && priorities.map(p => p.name).indexOf(priority) === -1) return
        return {
            backgroundColor: "#2e7d32",
            color: "white"
        };
    }

    const onMultipleSelectChange = (event) => {
        const { value, name } = event.target
        const newSelection = typeof value === 'string' ? value.split(',') : value;
        const uniqueSelection = newSelection.reduce((acc, priority) => {
            const exists = acc.find(item => item.id === priority.id);
            if (exists) return acc.filter(item => item.id !== priority.id);
            else return [...acc, priority];
        }, []);

        setSearchParams((prev) => ({
            ...prev,
            [name]: uniqueSelection,
        }));
    };

    return (
        <>
            <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => handleExtension()}>
                <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
                    Keresési Feltételek
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columns={20} spacing={2} padding={"10px"}>

                        <Grid item lg={5} md={20} xs={20} sx={{ padding: "0px" }}>
                            <Autocomplete
                                value={(searchParams && searchParams.project) || null}
                                margin="normal"
                                id="project"
                                onChange={(event, newValue) => onSearchParamsChange("project", newValue)}
                                options={projects}
                                getOptionLabel={(project) => project.name}
                                sx={{ marginTop: "8px" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Projekt Szűrő" />
                                )}
                            />
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "8px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="priorities-label">Prioritás</InputLabel>
                                <Select
                                    labelId="priorities-label"
                                    multiple
                                    value={searchParams.priorities || []}
                                    onChange={onMultipleSelectChange}
                                    name="priorities"
                                    variant="outlined"
                                    input={<OutlinedInput id="select-multiple-chip" label="Prioritás" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.0, margin: "-5px" }}>
                                            {selected.map((value) => (
                                                <Chip key={value.id} label={value.name} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {priorities.map((priority) => (
                                        <MenuItem
                                            key={priority.id}
                                            value={priority}
                                            style={getStyles(priority.name, searchParams.priorities)}
                                        >
                                            {priority.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "8px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="statuses-label">Státusz</InputLabel>
                                <Select
                                    labelId="statuses-label"
                                    multiple
                                    value={searchParams.statuses || []}
                                    onChange={onMultipleSelectChange}
                                    name="statuses"
                                    variant="outlined"
                                    input={<OutlinedInput id="select-multiple-chip" label="Státusz" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.0, margin: "-5px" }}>
                                            {selected.map((value) => (
                                                <Chip key={value.id} label={value.name} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {statuses.map((status) => (
                                        <MenuItem
                                            key={status.id}
                                            value={status}
                                            style={getStyles(status.name, searchParams.statuses)}
                                        >
                                            {status.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px", marginTop: "8px" }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Feladat Leírása"
                                name="name"
                                autoComplete="off"
                                onChange={(event) => onSearchParamsChange("description", event.target.value)}
                                sx={{ margin: "0px" }}
                                value={(searchParams && searchParams.description) || ""}
                            />
                        </Grid>

                        <Grid item lg={5} md={20} xs={30} sx={{ padding: "0px" }}>
                            <Autocomplete
                                value={(searchParams && searchParams.taskType) || null}
                                margin="normal"
                                id="taskType"
                                onChange={(event, newValue) => onSearchParamsChange("taskType", newValue)}
                                options={taskTypes}
                                getOptionLabel={(taskType) => taskType.name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Feladatkör" />
                                )}
                            />
                        </Grid>

                        <Grid item lg={5} md={20} xs={20} sx={{ padding: "0px" }}>
                            <Autocomplete
                                value={(searchParams && searchParams.vehicle) || null}
                                margin="normal"
                                id="vehicle"
                                onChange={(event, newValue) => onSearchParamsChange("vehicle", newValue)}
                                options={vehicles}
                                getOptionLabel={(vehicle) => vehicle.licensePlate}
                                renderInput={(params) => (
                                    <TextField {...params} label="Jármű" />
                                )}
                            />
                        </Grid>

                        <Grid item xl={5} lg={10} md={10} xs={20}>
                            <FormControl sx={{ m: 1, minWidth: 120, marginTop: "0px", marginLeft: "0px" }} fullWidth>
                                <InputLabel id="users-label">Dolgozók</InputLabel>
                                <Select
                                    labelId="users-label"
                                    multiple
                                    value={searchParams.users || []}
                                    onChange={onUsersChange}
                                    label="Dolgozók"
                                    variant="outlined"
                                    input={<OutlinedInput id="select-multiple-chip" label="Dolgozók" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.0, margin: "-5px" }}>
                                            {selected.map((value) => (
                                                <Chip key={value.id} label={value.name} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {users.map((user) => (
                                        <MenuItem
                                            key={user.id}
                                            value={user}
                                            style={getStyles(user.name, searchParams.users)}
                                        >
                                            {user.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item lg={5} md={10} xs={20} sx={{ padding: "0px", margin: "0px 0px 10px 0px" }}>
                            <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "135px" }} align='right' padding='none'>
                                    <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                                        <GetApp fontSize="large" />
                                    </Button>
                                    <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/project-tasks/create")}>
                                        <Add fontSize="large" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                rows.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                let value = column.subId != null ? (row[column.id] == null ? null : row[column.id][column.subId]) : row[column.id];
                                                if (column.id === 'users')
                                                    value = [...new Set(row.subTasks.filter(st => st.users != null).flatMap(st => st.users).map(user => user.name))].join(", ")
                                                else if (column.id === 'progressLevel')
                                                    value = <PercentageProgressBar fullWidth percentage={row[column.id]} narrow readonly color={"rgba(0,255,0,0.4)"} align='center' />
                                                else if (column.id === 'subTasks')
                                                    value = row[column.id].length
                                                else if (column.id === 'vehicles')
                                                    value = [...new Set(row.subTasks.filter(st => st.vehicle != null).map(st => st.vehicle.licensePlate))].join(", ")
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === 'project' ? <Link sx={{ fontWeight: "bold", textDecoration: "none" }} align="left" component="button" variant="body2" onClick={() => navigate("/dashboard/projects/" + row.project.id)}>{value}</Link> : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell key="actions" padding='none' align='right'>
                                                <Button color='primary' variant='contained' padding='30px'
                                                    sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/project-tasks/" + row.id)}>
                                                    <Edit />
                                                </Button>
                                                <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                    onClick={() => handleClickOpen(row)}>
                                                    <Delete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={parseInt(rowsPerPage)}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>

            <ConfirmationDialog
                title={"Biztosan törölni szeretnéd az alábbi feladatot?"}
                content={
                    <>
                        {dialogState.object?.project.name}<br />
                        {dialogState.object?.priority}<br />
                        {dialogState.object?.status}<br />
                        {dialogState.object?.description || ''}
                    </>
                }
                open={dialogState.open}
                onClose={handleClose}
                onConfirm={() => {
                    setRows(null)
                    projectTaskService.deleteProjectTask(dialogState.object?.id, (response, error) => { if (!error) retrieveList() })
                }}
            />
        </>
    );
}
