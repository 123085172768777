import Service from "./Service"

class SubStorageService extends Service {

    findById = (id, callback) => this.get("/sub-storages/" + id, callback)

    search = (queryParams, callback) => this.getWithParams("/sub-storages?", queryParams, callback)

    createSubStorage = (subStorageRequest, callback) => this.post("/sub-storages", subStorageRequest, callback)

    updateSubStorage = (subStorageId, subStorageRequest, callback) => this.patch("/sub-storages/" + subStorageId, subStorageRequest, callback)

    deleteSubStorage = (subStorageId, callback) => this.del("/sub-storages/" + subStorageId, callback)
}
const subStorageService = new SubStorageService()
export default subStorageService