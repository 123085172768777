import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import globalPaymentModifierService from '../services/GlobalPaymentModifierService';
import GlobalPaymentModifierEditorTemplate from './GlobalPaymentModifierEditorTemplate';

const GlobalPaymentModifierEditor = () => {

  const [id] = useState(
    window.location.pathname.split('/')[3] === 'create'
      ? undefined
      : window.location.pathname.split('/')[3]
  );

  const [globalPaymentModifier, setGlobalPaymentModifier] = useState({
    name: "",
    label: "",
    value: "",
    createdAt: "",
    updatedAt: ""
  });

  const [message, setMessage] = useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      globalPaymentModifierService.findById(
        id,
        (response, error) => {
          if (response) {
            console.log(response)
            setGlobalPaymentModifier((prevGlobalPaymentModifier) => ({
              ...prevGlobalPaymentModifier,
              ...response,
            }));
          } else {
            console.log(error);
          }
        },
      );
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setGlobalPaymentModifier((prevGlobalPaymentModifier) => ({
      ...prevGlobalPaymentModifier,
      [name]: value,
    }));
  };

  const update = (e) => {
    e.preventDefault();
    globalPaymentModifierService.updateGlobalPaymentModifier(id, globalPaymentModifier, (response, error) => {
      if (!error) {
        navigate('/dashboard/global-payment-modifiers');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  return (
    <GlobalPaymentModifierEditorTemplate
      data={{
        id,
        globalPaymentModifier,
        message,
        onChange,
        update,
      }}
    />
  );
};

export default GlobalPaymentModifierEditor;
