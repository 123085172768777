import React, { useState, useEffect } from 'react';
import VehicleEditorTemplate from './VehicleEditorTemplate';
import vehicleService from '../../services/VehicleService';
import { useNavigate } from 'react-router-dom';

const VehicleEditor = () => {

  const [id] = useState(
    window.location.pathname.split('/')[3] === 'create'
      ? undefined
      : window.location.pathname.split('/')[3]
  );

  const [vehicle, setVehicle] = useState({
    licensePlate: "",
    technicalValidity: "",
    lastEngineOilChange: "",
    odometer: "",
    highwayVignetteType: "",
    highwayVignetteValidity: "",
    insuranceValidity: "",
    createdAt: "",
    updatedAt: ""
  });

  const [message, setMessage] = useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      vehicleService.findById(
        id,
        (response, error) => {
          if (response) {
            console.log(response)
            setVehicle((prevVehicle) => ({
              ...prevVehicle,
              ...response,
            }));
          } else {
            console.log(error);
          }
        },
      );
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setVehicle((prevVehicle) => ({
      ...prevVehicle,
      [name]: value,
    }));
  };

  const create = (e) => {
    e.preventDefault();
    vehicleService.createVehicle(vehicle, (response, error) => {
      if (!error) {
        navigate(-1);
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    vehicleService.updateVehicle(id, vehicle, (response, error) => {
      if (!error) {
        navigate(-1);
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  return (
    <VehicleEditorTemplate
      data={{
        id,
        vehicle,
        message,
        onChange,
        create,
        update,
      }}
    />
  );
};

export default VehicleEditor;
