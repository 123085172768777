import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Checkbox, Container, Divider, FormControlLabel, Grid, TextField } from '@mui/material';
import payrollService from '../../../services/PayrollService';

export default function SalaryDetails() {

    const [userId] = React.useState(window.location.pathname.split('/')[3])
    const [year] = React.useState(window.location.pathname.split('/')[5])
    const [month] = React.useState(window.location.pathname.split('/')[7])
    const [salaryDetails, setSalaryDetails] = React.useState({
        compensationAmount: "",
        compensationComment: "",
        paid: false
    })
    const [message, setMessage] = React.useState('')


    useEffect(() => {
        payrollService.getSalaryDetails(userId, year, month,
            (response, error) => {
                if (response) {
                    setSalaryDetails(response);
                }
            }
        );
    }, [userId, year, month]);

    const update = (e) => {
        e.preventDefault()
        salaryDetails.userId = userId
        salaryDetails.year = year
        salaryDetails.month = month
        console.log(salaryDetails)
        payrollService.saveSalaryDetails(salaryDetails, (response, error) => {
            if (!error) navigate(-1)
            else setMessage(error)
        })
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        setSalaryDetails((prevSalaryDetails) => ({
            ...prevSalaryDetails,
            [name]: value,
        }));
    };

    const onChangeCheckbox = (event) => {
        const { checked, name } = event.target;
        setSalaryDetails((prevSalaryDetails) => ({
            ...prevSalaryDetails,
            [name]: checked,
        }));
    };

    const navigate = useNavigate();

    return (
        <>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={update}>
                        <Grid container spacing={2} columns={20}>
                            {createTextField("compensationAmount", "Kompenzáció Összege", salaryDetails.compensationAmount, onChange, false, true)}

                            <Grid item xl={5} lg={5} md={15} xs={15} style={{ textAlign: "left" }}>
                                <FormControlLabel control={<Checkbox
                                    variant="outlined"
                                    margin="normal"
                                    size="large"
                                    id="paid"
                                    label="paid"
                                    name="paid"
                                    onChange={onChangeCheckbox}
                                    checked={salaryDetails.paid}
                                    value={salaryDetails.paid}
                                />} label="Kifizetve"
                                    style={{ paddingTop: "20px" }} />
                            </Grid>

                            <Grid item lg={10} md={20} xs={20}></Grid>

                            <Grid item lg={5} md={10} xs={20}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="compensationComment"
                                    label="Megjegyzés"
                                    name="compensationComment"
                                    type="text"
                                    autoComplete="off"
                                    onChange={onChange}
                                    value={salaryDetails.compensationComment}
                                    sx={{marginTop: "0px"}}
                                    multiline
                                    rows={5}
                                />
                            </Grid>

                            {salaryDetails.createdAt !== undefined &&
                                <>
                                    <Grid item lg={20} md={20} xs={20}>
                                        <Divider />
                                    </Grid>

                                    {createTextField("createdAt", "Létrehozva", salaryDetails.createdAt, onChange, false, false, true)}
                                    {createTextField("updatedAt", "Módosítva", salaryDetails.updatedAt, onChange, false, false, true)}
                                    <Grid item lg={10} xs={20}></Grid>
                                </>

                            }

                            {message &&
                                <Grid item lg={20} xs={20}>
                                    <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                </Grid>
                            }

                            <Grid item lg={20} md={20} xs={20}></Grid>
                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="button" variant="contained" color="error" onClick={() => navigate(-1)} style={{ width: "150px", marginTop: "10px" }}>
                                    Vissza
                                </Button>
                            </Grid>
                            <Grid item lg={16} md={10} xs={2}></Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="submit" fullWidth variant="contained" color="success">
                                    Mentés
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </div>
            </Container >
        </>
    );
}

function createTextField(name, label, value, onChange, required = false, autoFocus = false, disabled = false) {
    return (<Grid item lg={5} md={10} xs={20}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type="text"
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value === undefined ? "" : value}
            disabled={disabled}
        />
    </Grid>)
}