import React, { useState, useEffect } from 'react';
import RegisterTemplate from './UserEditorTemplate';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';

const UserEditor = () => {
  
  const [id] = useState(
    window.location.pathname.split('/')[3] === 'register'
      ? undefined
      : window.location.pathname.split('/')[3]
  );

  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    passwordCheck: '',
    hourlyRate: '0',
    role: 'ROLE_EMPLOYEE',
    salaryTemplate: 'NORMAL',
    monthlySzja: '0',
    yearlyDayOffs: '0',
    availableDayOffs: '0',
    monthlyFuelCost: '0',
    dailySicknessBenefitAmount: '0',
    transferredSalaryAmount: '0',
    active: true
  });

  const [message, setMessage] = useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      UserService.findById(
        id,
        (response, error) => {
          if (response) {
            setUser((prevUser) => ({
              ...prevUser,
              ...response,
            }));
          } else {
            console.log(error);
          }
        },
      );
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const register = (e) => {
    e.preventDefault();
    if (user.password !== user.passwordCheck) {
      setMessage('Jelszavak nem egyeznek');
      return;
    }
    UserService.register(user, (response, error) => {
      if (!error) {
        navigate('/dashboard/users');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    UserService.updateUser(id, user, (response, error) => {
      if (!error) {
        navigate('/dashboard/users');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  return (
    <RegisterTemplate
      data={{
        id,
        user,
        message,
        onChange,
        register,
        update,
      }}
    />
  );
};

export default UserEditor;
