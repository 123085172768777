import * as React from 'react';
import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import history from './History';

import AnimatedComingSoon from './components/ComingSoon';
import authService from './services/AuthService';
import ProjectTable from './components/projects/Projects';
import ProjectEditor from './components/projects/ProjectEditor';
import UserEditor from './components/users/UserEditor';
import UserTable from './components/users/Users';
import SubStorageTable from './components/substorages/SubStorages';
import SubStorageEditor from './components/substorages/SubStorageEditor';
import VehicleTable from './components/vehicles/Vehicles';
import VehicleEditor from './components/vehicles/VehicleEditor';
import TaskTypeTable from './components/task-types/TaskTypes';
import TaskTypeEditor from './components/task-types/TaskTypeEditor';
import GlobalPaymentModifierTable from './global-payment-modifiers/GlobalPaymentModifiers';
import GlobalPaymentModifierEditor from './global-payment-modifiers/GlobalPaymentModifierEditor';
import TaskLogTable from './components/task-logs/TaskLogs';
import TaskLogEditor from './components/task-logs/TaskLogEditor';
import InvoiceTable from './components/invoices/Invoices';
import Invoice from './components/invoices/Invoice';
import InvoiceList from './components/invoices/InvoiceList';
import NoteTable from './components/notes/Notes';
import NoteEditor from './components/notes/NoteEditor';
import Payroll from './components/payrolls/Payroll';
import PayrollTable from './components/payrolls/Payrolls';
import Compensation from './components/payrolls/salary-details/SalaryDetails';
import VehicleLogTable from './components/vehicles/vehicle-logs/VehicleLogs';
import VehicleLogEditor from './components/vehicles/vehicle-logs/VehicleLogEditor';
import ProductMoving from './components/products/ProductMoving';
import ChangeLogs from './components/change-logs/ChangeLogs';
import ProductTable from './components/products/Products';
import ProductEditor from './components/products/ProductEditor';
import ProductItemEditor from './components/products/ProductItemEditor';
import StorageLogTable from './components/storage-logs/StorageLogs';
import ProjectTaskTable from './components/project-tasks/ProjectTasks';
import ProjectTaskEditor from './components/project-tasks/ProjectTaskEditor';
import ProjectSubTaskComments from './components/project-task-comments/ProjectSubTaskComments';
import ProjectSubTaskEditor from './components/project-subtasks/ProjectSubTaskEditor';
import { TitleProvider } from './components/dashboard/TitleContext';

export const API_URL = process.env.REACT_APP_API_URL

export default function App() {

  return (
    <>
      <TitleProvider>
        <div className="root">
          <Router>
            <Routes>
              {!authService.getCurrentUser() && history.push("/login")}
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/dashboard/change-logs" element={<Dashboard content={<ChangeLogs />} oldTitle="Verzió Történet" />} />
              <Route exact path='/dashboard/task-logs' element={<Dashboard content={<TaskLogTable />} oldTitle="Munkaidő" />} />
              <Route exact path='/dashboard/task-logs/create' element={<Dashboard content={<TaskLogEditor />} oldTitle="Munkaidő Bejegyzés Létrehozása" />} />
              <Route exact path='/dashboard/task-logs/:id' element={<Dashboard content={<TaskLogEditor />} oldTitle="Munkaidő Bejegyzés Szerkesztése" />} />
              <Route exact path='/dashboard/task-logs/:taskLogId/task-log-items/:id/invoices' element={<Dashboard content={<InvoiceTable />} oldTitle="Munkaidő Bejegyzéshez Tartozó Számlák" />} />
              <Route exact path='/dashboard/invoices/:invoiceId' element={<Dashboard content={<Invoice />} oldTitle="Munkaidő Bejegyzéshez Tartozó Számla" />} />
              <Route exact path='/dashboard/worksheets' element={<Dashboard content={<AnimatedComingSoon />} oldTitle="Munkalap" />} />
              <Route exact path='/dashboard/storage' element={<Dashboard content={<ProductTable />} oldTitle="Raktár Kezelése" />} />
              <Route exact path='/dashboard/storage-logs' element={<Dashboard content={<StorageLogTable />} oldTitle="Raktár Történet" />} />
              <Route exact path='/dashboard/storage/:id' element={<Dashboard content={<ProductEditor />} oldTitle="Termék Részletei" />} />
              <Route exact path='/dashboard/storage/item/:id' element={<Dashboard content={<ProductItemEditor />} oldTitle="Termék Elem Szerkesztése" />} />
              <Route exact path='/dashboard/storage/move' element={<Dashboard content={<ProductMoving />} oldTitle="Raktár Elemek Mozgatása" />} />
              <Route exact path='/dashboard/storage/scrapping' element={<Dashboard content={<AnimatedComingSoon />} oldTitle="Termék Selejtezése" />} />
              <Route exact path='/dashboard/storage/logs' element={<Dashboard content={<AnimatedComingSoon />} oldTitle="Raktár Történet" />} />
              <Route exact path='/dashboard/storage/substorages' element={<Dashboard content={<SubStorageTable />} oldTitle="Alraktárak Kezelése" />} />
              <Route exact path='/dashboard/storage/substorages/create' element={<Dashboard content={<SubStorageEditor />} oldTitle="Alraktár Hozzáadása" />} />
              <Route exact path='/dashboard/storage/substorages/:id' element={<Dashboard content={<SubStorageEditor />} oldTitle="Alraktár Szerkesztése" />} />
              <Route exact path='/dashboard/statistics' element={<Dashboard content={<AnimatedComingSoon />} oldTitle="Statisztika" />} />
              <Route exact path='/dashboard/projects' element={<Dashboard content={<ProjectTable />} oldTitle="Projektek Kezelése" />} />
              <Route exact path='/dashboard/projects/create' element={<Dashboard content={<ProjectEditor />} oldTitle="Projekt Hozzáadása" />} />
              <Route exact path='/dashboard/projects/:id' element={<Dashboard content={<ProjectEditor />} oldTitle="Projekt Szerkesztése" />} />
              <Route exact path='/dashboard/project-tasks' element={<Dashboard content={<ProjectTaskTable />} />} />
              <Route exact path='/dashboard/project-tasks/create' element={<Dashboard content={<ProjectTaskEditor />} />} />
              <Route exact path='/dashboard/project-tasks/:id' element={<Dashboard content={<ProjectTaskEditor />} />} />
              <Route exact path='/dashboard/project-tasks/:id/sub-tasks/create' element={<Dashboard content={<ProjectSubTaskEditor />} />} />
              <Route exact path='/dashboard/project-tasks/:id/sub-tasks/:id' element={<Dashboard content={<ProjectSubTaskEditor />} />} />
              <Route exact path='/dashboard/task-types' element={<Dashboard content={<TaskTypeTable />} oldTitle="Feladatkörök Kezelése" />} />
              <Route exact path='/dashboard/task-types/create' element={<Dashboard content={<TaskTypeEditor />} oldTitle="Feladatkör Hozzáadása" />} />
              <Route exact path='/dashboard/task-types/:id' element={<Dashboard content={<TaskTypeEditor />} oldTitle="Feladatkör Szerkesztése" />} />
              <Route exact path='/dashboard/worksheets/global' element={<Dashboard content={<AnimatedComingSoon />} oldTitle="Munkalapok" />} />
              <Route exact path='/dashboard/users' element={<Dashboard content={<UserTable />} oldTitle="Felhasználók Kezelése" />} />
              <Route exact path='/dashboard/users/register' element={<Dashboard content={<UserEditor />} oldTitle="Felhasználó Hozzáadása" />} />
              <Route exact path='/dashboard/users/:id' element={<Dashboard content={<UserEditor />} oldTitle="Felhasználó Szerkesztése" />} />
              <Route exact path='/dashboard/vehicles' element={<Dashboard content={<VehicleTable />} oldTitle="Járművek Kezelése" />} />
              <Route exact path='/dashboard/vehicles/create' element={<Dashboard content={<VehicleEditor />} oldTitle="Jármű Hozzáadása" />} />
              <Route exact path='/dashboard/vehicles/:id' element={<Dashboard content={<VehicleEditor />} oldTitle="Jármű Szerkesztése" />} />
              <Route exact path='/dashboard/vehicles/:id/vehicle-logs' element={<Dashboard content={<VehicleLogTable />} oldTitle="Jármű Történet" />} />
              <Route exact path='/dashboard/vehicles/:id/vehicle-logs/create' element={<Dashboard content={<VehicleLogEditor />} oldTitle="Jármű Bejegyzés Hozzáadása" />} />
              <Route exact path='/dashboard/vehicles/:id/vehicle-logs/:id' element={<Dashboard content={<VehicleLogEditor />} oldTitle="Jármű Bejegyzés Szerkesztése" />} />
              <Route exact path='/dashboard/global-payment-modifiers' element={<Dashboard content={<GlobalPaymentModifierTable />} oldTitle="Globális Fizetési Beállítások" />} />
              <Route exact path='/dashboard/global-payment-modifiers/:id' element={<Dashboard content={<GlobalPaymentModifierEditor />} oldTitle="Globális Beállítás Szerkesztése" />} />
              <Route exact path='/dashboard/payrolls' element={<Dashboard content={<PayrollTable />} oldTitle="Bérjegyzék Lista" />} />
              <Route exact path='/dashboard/payrolls/:userId/year/:year/month/:month' element={<Dashboard content={<Payroll />} oldTitle="Bérjegyzék" />} />
              <Route exact path='/dashboard/compensations/:userId/year/:year/month/:month' element={<Dashboard content={<Compensation />} oldTitle="Kompenzáció" />} />
              <Route exact path='/dashboard/invoices' element={<Dashboard content={<InvoiceList />} oldTitle="Számlák" />} />
              <Route exact path='/dashboard/notes' element={<Dashboard content={<NoteTable />} oldTitle="Jegyzetek" />} />
              <Route exact path='/dashboard/notes/create' element={<Dashboard content={<NoteEditor />} oldTitle="Jegyzet Hozzáadása" />} />
              <Route exact path='/dashboard/notes/:id' element={<Dashboard content={<NoteEditor />} oldTitle="Jegyzet Szerkesztése" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </div>
      </TitleProvider>
    </>
  );
}

function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/dashboard/task-logs');
  }, [navigate]);
}
