import { Button, Container, TextField, Alert, Grid } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';

export default function NoteEditorTemplate(props) {

    const { id, onChange, create, update, message } = props.data

    const { text } = props.data.note

    const navigate = useNavigate();

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={id ? update : create}>
                        <Grid container spacing={2} columns={20}>
                            <Grid item lg={20} md={20} xs={20}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="text"
                                    label="Jegyzet"
                                    name="text"
                                    type="text"
                                    autoFocus
                                    autoComplete="off"
                                    onChange={onChange}
                                    value={text}
                                    multiline={true}
                                    rows={20}
                                />
                            </Grid>

                            {message &&
                                <Grid item lg={20} xs={20}>
                                    <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                </Grid>
                            }

                            <Grid item lg={20} md={20} xs={20}></Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
                                    Vissza
                                </Button>
                            </Grid>

                            <Grid item lg={16} md={10} xs={2}></Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="submit" fullWidth variant="contained" color="success">
                                    Mentés
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </div>

            </Container>
        </div>
    )
}