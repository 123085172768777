import Service from "./Service"

class GlobalPaymentModifierService extends Service {

    findById = (id, callback) => this.get("/global-payment-modifiers/" + id, callback)

    findAll = (callback) => this.get("/global-payment-modifiers", callback)

    updateGlobalPaymentModifier = (tasktypeId, globalPaymentModifierRequest, callback) => this.patch("/global-payment-modifiers/" + tasktypeId, globalPaymentModifierRequest, callback)
}
const globalPaymentModifierService = new GlobalPaymentModifierService()
export default globalPaymentModifierService