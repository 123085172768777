import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import LoginTemplate from "./LoginTemplate";
import { useNavigate } from "react-router-dom";

const Login = () => {

	const navigate = useNavigate()

	const [state, setState] = useState({
		email: "",
		password: "",
		message: "",
	});

	const onChange = (event) => {
		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const login = (e) => {
		e.preventDefault();
		setState((prevState) => ({ ...prevState, message: '' }));
		AuthService.login(state.email, state.password, (response, error) => {
			if (response) {
				localStorage.setItem("user", JSON.stringify(response));
				navigate("/dashboard/task-logs");
				window.location.reload();
			} else {
				console.log(error)
				setState((prevState) => ({ ...prevState, message: error }));
			}
		});
	};

	return <LoginTemplate data={{ ...state, onChange, login }} />;
};

export default Login;
