import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import { Add, Delete, GetApp, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import { API_URL } from '../../App';
import invoiceService from '../../services/InvoiceService';
import authService from '../../services/AuthService';
import axios from 'axios';
import { saveAs } from 'file-saver';

const columns = [
    { id: 'preview', label: 'Előnézet' },
    { id: 'imageUrl', label: 'Útvonal' },
    { id: 'createdAt', label: 'Létrehozva' }
];

export default function InvoiceTable() {

    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [taskLogId] = useState(window.location.pathname.split('/')[3]);
    const [taskLogItemId] = useState(window.location.pathname.split('/')[5]);

    useEffect(() => {
        invoiceService.getInvoices(taskLogItemId,
            (response, error) => {
                if (response) {
                    console.log(response)
                    setRows(response);
                } else console.log(error)
            }
        );
    }, [taskLogId, taskLogItemId]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const downloadInvoice = (id) => {
        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        axios({
            url: API_URL + "/invoices/" + id,
            method: 'GET',
            responseType: 'blob',
            headers: headers,
        })
            .then(response => saveAs(response.data, "image.png"))
            .catch(error => console.error(error));
    }

    const navigate = useNavigate();

    const VisuallyHiddenInput = styled('input')({
        width: 0
    });

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                                    <Button component="label" variant="contained" color='success' style={{ padding: "0px", minWidth: "30px", marginRight: "10px" }}>
                                        <Add fontSize="large" />
                                        <VisuallyHiddenInput type="file"
                                            onChange={(e) => {
                                                const formData = new FormData();
                                                formData.append('file', e.target.files[0]);
                                                invoiceService.createInvoice(taskLogItemId, formData, (response, error) => {
                                                    if (error) console.log(error)
                                                    else navigate(0)
                                                })
                                            }}
                                        />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                let value;
                                                if (column.id === 'preview') {
                                                    const blob = new Blob([new Uint8Array([...atob(row[column.id])]
                                                        .map(char => char.charCodeAt(0)))], { type: 'image/png' });
                                                    const imageUrl = URL.createObjectURL(blob);
                                                    value = <img src={imageUrl} alt="" />
                                                }
                                                else value = row[column.id] ? row[column.id].toLocaleString() : row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell key="actions" padding='none' align='right'>
                                                <Button variant='contained' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }}
                                                    onClick={() => navigate("/dashboard/invoices/" + row.id)}>
                                                    <Search fontSize="large" />
                                                </Button>
                                                <Button variant='contained' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }}
                                                onClick={() => downloadInvoice(row.id)}>
                                                    <GetApp fontSize="large" />
                                                </Button>
                                                <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                    onClick={() => invoiceService.deleteInvoice(row.id, (response, error) => {
                                                        if (!error) {
                                                            navigate(0)
                                                        }
                                                    })}>
                                                    <Delete />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ position: "relative" }}>
                    <Button type="button" variant="contained" color="error" onClick={() => navigate("/dashboard/task-logs")} style={{ position: "absolute", margin: "8px", zIndex: 1 }}>
                        Vissza
                    </Button>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />


            </Paper>
        </>
    );
}
