import * as React from 'react';
import { API_URL } from '../../App';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import authService from '../../services/AuthService';

export default function Invoice() {

    const inVoiceId = window.location.pathname.split('/')[3];

    const [image, setImage] = React.useState();

    React.useEffect(() => {
        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        axios({
            url: API_URL + "/invoices/" + inVoiceId,
            method: 'GET',
            responseType: 'blob',
            headers: headers,
        })
            .then(response => setImage(URL.createObjectURL(new Blob([response.data]))))
            .catch(error => console.error(error));
    }, [inVoiceId]);

    const navigate = useNavigate()

    return (
        <div style={{ textAlign: "center", width: "100%" }}>
            <Button type="button" variant="contained" color="error"
                onClick={() => navigate(-1)}
                style={{ float: "left" }}>
                Vissza
            </Button>
            <img src={image} alt=""/>
        </div>
    );
}
