import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Alert, Button, Slide, Snackbar, Tooltip } from '@mui/material';
import { Add, Archive, GetApp, Search, Unarchive } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import invoiceService from '../../services/InvoiceService';
import { API_URL } from '../../App';
import { saveAs } from 'file-saver';
import authService from '../../services/AuthService';
import axios from 'axios';

const columns = [
    { id: 'preview', label: 'Előnézet' },
    { id: 'username', label: 'Feltöltő' },
    { id: 'projectName', label: 'Projekt' },
    { id: 'date', label: 'Dátum' }
];

export default function InvoiceList() {

    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        invoiceService.getAllInvoices(
            (response, error) => {
                if (response) {
                    console.log(response)
                    setRows(response);
                } else console.log(error)
            }
        );
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const archiveInvoice = (id) => {
        setRows(prevRows =>
            prevRows.map(row =>
                row.id === id ? { ...row, archived: !row.archived } : row
            )
        );
    };

    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState("");
    const [alertType, setAlertType] = React.useState("success");

    const sendAlertMessage = (text, type = 'success') => {
        setText(text)
        setAlertType(type)
        setOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    const downloadInvoice = (id) => {
        const headers = { 'Authorization': 'Bearer ' + authService.getCurrentUser().accessToken };
        axios({
            url: API_URL + "/invoices/" + id,
            method: 'GET',
            responseType: 'blob',
            headers: headers,
        })
            .then(response => saveAs(response.data, "image.png"))
            .catch(error => console.error(error));
    }

    const navigate = useNavigate();

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                                    <Button component="label" variant="contained" color='success' style={{ padding: "0px", minWidth: "30px", marginRight: "10px" }}>
                                        <Add fontSize="large" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                let value;
                                                if (column.id === 'preview') {
                                                    const blob = new Blob([new Uint8Array([...atob(row[column.id])]
                                                        .map(char => char.charCodeAt(0)))], { type: 'image/png' });
                                                    const imageUrl = URL.createObjectURL(blob);
                                                    value = <img src={imageUrl} alt="" />
                                                }
                                                else value = row[column.id] ? row[column.id].toLocaleString() : row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell key="actions" padding='none' align='right'>
                                                <Button variant='contained' color={row.archived ? 'error' : 'success'} sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }}
                                                    onClick={() => invoiceService.archiveInvoice(row.id, !row.archived, (response, error) => {
                                                        if (error) {
                                                            console.log(error)
                                                            sendAlertMessage(!row.archived ? "Számla archiválása sikertelen!" : "Számla archiválásának visszavonása sikertelen!", 'error')
                                                        }
                                                        else {
                                                            archiveInvoice(row.id)
                                                            sendAlertMessage(!row.archived ? "Számla sikeresen archiválva!" : "Számla archiválása sikeresen visszavonva!")
                                                        }
                                                    })}>
                                                    {row.archived ?
                                                        <Tooltip title="Archiválás Visszavonása">
                                                            <Unarchive fontSize="large" />
                                                        </Tooltip> :
                                                        <Tooltip title="Archiválás">
                                                            <Archive fontSize="large" />
                                                        </Tooltip>
                                                    }
                                                </Button>
                                                <Button variant='contained' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }}
                                                    onClick={() => navigate("/dashboard/invoices/" + row.id)}>
                                                    <Search fontSize="large" />
                                                </Button>
                                                <Button variant='contained' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} 
                                                onClick={() => downloadInvoice(row.id)}>
                                                    <GetApp fontSize="large" />
                                                </Button>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ position: "relative" }}>
                    <Button type="button" variant="contained" color="error" onClick={() => navigate("/dashboard/task-logs")} style={{ position: "absolute", margin: "8px", zIndex: 1 }}>
                        Vissza
                    </Button>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />


            </Paper>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionComponent={(props) => <Slide  {...props} direction="down" />}
            >
                <Alert elevation={10} onClose={handleClose} severity={alertType} sx={{ width: '100%' }} variant='filled'>
                    {text}
                </Alert>
            </Snackbar>
        </>
    );
}
