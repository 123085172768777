import { Button, Container, TextField, Alert, Grid, Divider } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';

export default function SubStorageEditorTemplate(props) {

	const { id, onChange, create, update, message } = props.data

	const { name, createdAt, updatedAt } = props.data.subStorage

	const navigate = useNavigate();

	return (
		<div>
			<Container component="main">
				<div className="register-paper">
					<form id="login" onSubmit={id ? update : create}>
						<Grid container spacing={2} columns={20}>
							{createInputField("name", "Alraktár Neve", name, onChange, true, true)}

							{id !== undefined &&
								<>
									<Grid item lg={20} md={20} xs={20}>
										<Divider />
									</Grid>

									{createInputField("createdAt", "Létrehozva", createdAt, onChange, false, false, true)}
									{createInputField("updatedAt", "Módosítva", updatedAt, onChange, false, false, true)}
									<Grid item lg={10} xs={20}></Grid>
								</>

							}

							{message &&
								<Grid item lg={20} xs={20}>
									<Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
								</Grid>
							}

							<Grid item lg={20} md={20} xs={20}></Grid>


								<Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate("/dashboard/storage/substorages")}>
									Vissza
								</Button>

							<Grid item lg={16} md={10} xs={2}></Grid>

							<Grid item lg={2} md={5} xs={9}>
								<Button type="submit" fullWidth variant="contained" color="success">
									Mentés
								</Button>
							</Grid>

						</Grid>
					</form>
				</div>

			</Container>
		</div>
	)
}

function createInputField(name, label, value, onChange, required = false, autoFocus = false, disabled = false) {
	return (<Grid item lg={5} md={10} xs={20}>
		<TextField
			variant="outlined"
			margin="normal"
			required={required}
			fullWidth
			id={name}
			label={label}
			name={name}
			type="text"
			autoFocus={autoFocus}
			autoComplete="off"
			onChange={onChange}
			value={value}
			disabled={disabled}
		/>
	</Grid>)
}