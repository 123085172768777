import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import userService from '../../services/UserService';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Add, Edit, GetApp, KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const columns = [
  { id: 'name', label: 'Név' },
  { id: 'email', label: 'Email' },
  { id: 'hourlyRate', label: 'Óradíj' },
  { id: 'monthlySzja', label: 'SZJA' },
  { id: 'yearlyDayOffs', label: 'Szabadság' },
  { id: 'monthlyFuelCost', label: 'Üzemanyag' },
  { id: 'dailySicknessBenefitAmount', label: 'Táppénz' },
  { id: 'transferredSalaryAmount', label: 'Átutalt Bér' },
  { id: 'role', label: 'Szerepkör' },
  { id: 'registrationDate', label: 'Regisztráció', align: 'right' },
  { id: 'lastLogin', label: 'Utolsó Bejelentkezés', align: 'right' }
];

export default function UserTable() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem("user-rows-per-page") || 24);
  const [expanded, setExpanded] = React.useState(false);
  const [searchParams, setSearchParams] = React.useState(JSON.parse(localStorage.getItem("user-search-params")) || {})
  const [totalCount, setTotalCount] = React.useState(0);

  useEffect(() => {
    userService.search(buildQueryParams(), (response, error) => {
      if (response) {
        console.log(response)
        const result = response.content.map((user) => {
          let role = "";
          switch (user.role) {
            case "ROLE_EMPLOYEE": role = "Dolgozó"; break;
            case "ROLE_CEO": role = "Cégvezető"; break;
            case "ROLE_ADMIN": role = "Adminisztrátor"; break;
            case "ROLE_EXTERNAL_SUBCONTRACTOR": role = "Külsős Alvállalkozó"; break;
            case "ROLE_STATISTICIAN": role = "Statisztikus"; break;
            default: role = "ERROR"; break;
          }
          return { ...user, role: role }
        })
        setTotalCount(response.totalElements)
        setRows(result);
      }
    }
    );
  }, [page, rowsPerPage, searchParams]);

  function buildQueryParams() {
    return {
      page: page,
      size: rowsPerPage,
      ...(searchParams.from && { from: searchParams.from }),
      ...(searchParams.to && { to: searchParams.to }),
      ...(searchParams.showInactives && { showInactives: searchParams.showInactives })
    }
  }

  const handleExtension = () => {
    setExpanded(!expanded);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value
    setRowsPerPage(event.target.value);
    localStorage.setItem("user-rows-per-page", rowsPerPage)
    setPage(0);
  };

  const onSearchParamsChange = (name, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [name]: value
    }))
  }

  const clear = () => {
    setSearchParams({})
  }

  const onChangeCheckbox = (event) => {
    const { checked, name } = event.target;
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [name]: checked,
    }));
  };

  useEffect(() => {
    if (searchParams) localStorage.setItem("user-search-params", JSON.stringify(searchParams));
    else localStorage.removeItem("user-search-params")
  }, [searchParams]);

  const navigate = useNavigate();

  console.log(rows)

  return (
    <>
      <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => handleExtension()}>
        <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
          Keresési Feltételek
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columns={20} spacing={2} padding={"10px"}>
            <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
              <FormControlLabel control={<Checkbox
                variant="outlined"
                margin="normal"
                size="large"
                label="Inaktívak Mutatása"
                name="showInactives"
                onChange={onChangeCheckbox}
                checked={searchParams.showInactives}
                value={searchParams.showInactives}
              />} label="Inaktívak Mutatása"/>
            </Grid>

            <Grid item lg={12} md={10} xs={20}></Grid>

            <Grid item md={4} xs={20} sx={{ padding: "0px", margin: "0px 0px 0px 0px" }}>
              <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                  <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                    <GetApp fontSize="large" />
                  </Button>
                  <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/users/register")}>
                    <Add fontSize="large" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                    <TableCell key="actions" padding='none' align='right'>
                      <Button color='primary' variant='contained' padding='30px'
                        sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/users/" + row.id)}>
                        <Edit />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Elemek oldalanként:"
        />
      </Paper>
    </>
  );
}
