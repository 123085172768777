import { Button, Container, TextField, Alert, Grid, Divider, Checkbox, FormControlLabel, Paper, Autocomplete, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Edit, GetApp, Settings } from "@mui/icons-material";

export default function ProductEditorTemplate(props) {

	const unit = ' ' + (props.data.product.unitOfMeasurement != null ? "(" + props.data.product.unitOfMeasurement + ")" : "")

	const columns = [
		{ id: 'subStorage', label: 'Alraktár', subId: "name" },
		{ id: 'amount', label: 'Mennyiség' + unit },
		{ id: 'minimumStockInStorage', label: 'Minimális mennyiség' + unit },
		{ id: 'purchasePrice', label: 'Vásárlási Ár (Ft)' },
		{ id: 'sellingPrice', label: 'Eladási Ár (Ft)' },
		{ id: 'factoryNumber', label: 'Gyári Szám' },
		{ id: 'updatedAt', label: 'Módosítva' },
	];

	const { id, onChange, onChangeCheckbox, onChangeSelectItem, create, update, correctAmount, message, waitingForServer } = props.data

	const { name, manufacturer, centralStorageLocation, ean, articleNumber, unitOfMeasurement, vtszSzjTeszor, component,
		stockManagement, dataSheetUrl, scoreSheetUrl, comment, createdAt, updatedAt, productItems } = props.data.product

	const navigate = useNavigate();

	const [open, setOpen] = React.useState(false);
	const [currentItem, setCurrentItem] = React.useState(undefined);

	const handleClickOpen = (row) => {
		setCurrentItem(row)
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Container component="main">
				<div className="register-paper">
					<form id="login" onSubmit={id ? update : create}>
						<Grid container spacing={2} columns={20}>

							{createTextField("name", name, "Megnevezés", onChange, true)}
							{createTextField("manufacturer", manufacturer, "Gyártó", onChange, true)}
							{createTextField("centralStorageLocation", centralStorageLocation, "Központi Raktár Hely", onChange)}
							{createTextField("ean", ean, "EAN", onChange)}
							{createTextField("articleNumber", articleNumber, "Cikkszám", onChange)}
							{createTextField("vtszSzjTeszor", vtszSzjTeszor, "Vtsz/Szj/TESZOR", onChange)}

							<Grid item lg={5} md={10} xs={20}>
								<Autocomplete
									value={unitOfMeasurement || ""}
									margin="normal"
									id="unitOfMeasurement"
									label="Mértékegység"
									name="unitOfMeasurement"
									freeSolo
									autoSelect
									onChange={(event, newValue) => onChangeSelectItem(newValue, "unitOfMeasurement")}
									options={["méter", "db", "tétel"]}
									getOptionLabel={(m) => m}
									style={{ marginTop: "16px" }}
									renderInput={(params) => (
										<TextField {...params} label="Mértékegység" />
									)}
								/>
							</Grid>

							{createTextField("component", component, "Komponensek", onChange)}

							<Grid item md={5} xs={20} sx={{ padding: "0px", textAlign: "left" }}>
								<FormControlLabel control={<Checkbox
									variant="outlined"
									margin="normal"
									size="large"
									id="stockManagement"
									label="stockManagement"
									name="stockManagement"
									onChange={onChangeCheckbox}
									checked={stockManagement || false}
									value={stockManagement || false}
								/>} label="Készletkezelés" />
							</Grid>
							{createTextField("dataSheetUrl", dataSheetUrl, "Adatlap URL", onChange)}
							{createTextField("scoreSheetUrl", scoreSheetUrl, "Teljesítménylap URL", onChange)}
							{createTextField("comment", comment, "Megjegyzés", onChange)}

							{id !== undefined &&
								<>
									<Grid item lg={20} md={20} xs={20}>
										<Divider />
									</Grid>

									{createTextField("createdAt", createdAt, "Létrehozva", onChange, false, false, true)}
									{createTextField("updatedAt", updatedAt, "Módosítva", onChange, false, false, true)}
									<Grid item lg={10} xs={20}></Grid>
								</>

							}

							{message &&
								<Grid item lg={20} xs={20}>
									<Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
								</Grid>
							}

							<Grid item lg={2} md={5} xs={9}>
								<Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
									Vissza
								</Button>
							</Grid>

							<Grid item lg={16} md={10} xs={2}></Grid>

							<Grid item lg={2} md={5} xs={9}>
								<Button type="submit" fullWidth variant="contained" color="success">
									Mentés
								</Button>
							</Grid>

						</Grid>
					</form>
				</div>

			</Container>

			<Paper sx={{ width: '100%', overflow: 'hidden', marginTop: "20px" }}>
				<TableContainer style={{ maxHeight: "calc(100vh - 160px)" }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow >
								{columns.map((column) => (
									<TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
										{column.label}
									</TableCell>
								))}
								<TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
									<Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
										<GetApp fontSize="large" />
									</Button>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{waitingForServer ? <TableRow sx={{ textAlign: "center" }}> <TableCell colSpan={8} sx={{ padding: "40px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> : productItems && productItems.map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
										{columns.map((column) => {
											let value = column.subId != null ? row[column.id][column.subId] : row[column.id];
											if(column.id == "subStorage")
												value = row.subStorage.name == null ? row.subStorage.project.name : row.subStorage.name
											return (
												<TableCell key={column.id} align={column.align}>
													{value == null ? "" : value.toLocaleString()}
												</TableCell>
											);
										})}
										<TableCell key="actions" padding='none' align='right'>
											<React.Fragment>
												<Button onClick={() => handleClickOpen(row)} color='secondary' variant='contained' padding='30px'
													sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}>
													<Settings />
												</Button>
												<Dialog
													open={open}
													onClose={handleClose}
													PaperProps={{
														component: 'form',
														onSubmit: (event) => {
															event.preventDefault();
															const formData = new FormData(event.currentTarget);
															const formJson = Object.fromEntries((formData).entries());
															correctAmount(currentItem.id, formJson.amount);
															handleClose();
														},
													}}
												>
													<DialogTitle>Termék Példány Mennyiség Korrekció</DialogTitle>
													<DialogContent>
														<TextField
															autoFocus
															required
															margin="dense"
															id={"amount" + row.id}
															name="amount"
															label="Mennyiség"
															fullWidth
															defaultValue={currentItem?.amount}
															variant="standard"
														/>
													</DialogContent>
													<DialogActions>
														<Button onClick={handleClose}>Mégse</Button>
														<Button type="submit">Mentés</Button>
													</DialogActions>
												</Dialog>
											</React.Fragment>
											<Button color='primary' variant='contained' padding='30px'
												sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/storage/item/" + row.id)}>
												<Edit />
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	)
}

function createTextField(name, value, label, onChange, required = false, autoFocus = false, disabled = false) {
	return (<Grid item lg={5} md={10} xs={20}>
		<TextField
			variant="outlined"
			margin="normal"
			required={required}
			fullWidth
			id={name}
			label={label}
			name={name}
			type="text"
			autoFocus={autoFocus}
			autoComplete="off"
			onChange={onChange}
			value={value || ""}
			disabled={disabled}
		/>
	</Grid>)
}