import Service from "./Service"

class ProjectTaskService extends Service {

    findById = (id, callback) => this.get("/project-tasks/" + id, callback)

    search = (searchParams, callback) => this.getWithParams("/project-tasks?", searchParams, callback)

    createProjectTask = (projectTaskRequest, callback) => this.post("/project-tasks", projectTaskRequest, callback)

    copyProjectTask = (copyRequest, callback) => this.put("/project-tasks", copyRequest, callback)

    updateProjectTask = (projectTaskId, projectTaskRequest, callback) => this.patch("/project-tasks/" + projectTaskId, projectTaskRequest, callback)

    deleteProjectTask = (projectTaskId, callback) => this.del("/project-tasks/" + projectTaskId, callback)
}
const projectTaskService = new ProjectTaskService()
export default projectTaskService