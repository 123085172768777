import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import storageLogService from '../../services/StorageLogService';
import { CircularProgress, Tooltip } from '@mui/material';

const columns = [
    { id: 'id', label: 'ID' },
    { id: 'user', label: 'Név' },
    { id: 'operationLabel', label: 'Művelet' },
    { id: 'productName', label: 'Termék' },
    { id: 'productManufacturer', label: 'Gyártó' },
    { id: 'subStorageFrom', label: 'Forrás' },
    { id: 'subStorageTo', label: 'Cél' },
    { id: 'change', label: 'Változás' },
    { id: 'createdAt', label: 'Dátum' }
];

export default function StorageLogTable() {
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState(undefined);
    const [totalCount, setTotalCount] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem("storage-logs-rows-per-page") || 25);

    useEffect(() => {
        setRows(null)
        storageLogService.search(page, rowsPerPage,
            (response, error) => {
                if (response) {
                    const result = response.content.map((log) => {
                        let operationLabel = "";
                        let color = "";
                        switch (log.operation) {
                            case "CREATE_PRODUCT":
                                operationLabel = "Termék létrehozása";
                                color = "#B4E380"
                                break;
                            case "CREATE_PRODUCT_ITEM":
                                operationLabel = "Termék példányok bevételezése ";
                                color = "#B4E380"
                                break;
                            case "UPDATE_PRODUCT":
                                operationLabel = "Termék szerkesztése";
                                color = "#F6FB7A"
                                break;
                            case "UPDATE_PRODUCT_ITEM":
                                operationLabel = "Termék példány szerkesztése";
                                color = "#F6FB7A"
                                break;
                            case "MOVE_PRODUCT_ITEM_WITH_AMOUNT_INCREASE":
                                operationLabel = "Termék mozgatása";
                                color = "#7BD3EA";
                                break;
                            case "MOVE_PRODUCT_ITEM":
                                operationLabel = "Termék mozgatása";
                                color = "#7BD3EA";
                                break;
                            case "DISCARD_PRODUCT_ITEMS":
                                operationLabel = "Termék példány leselejtezése";
                                color = "#FA7070"
                                break;
                            case "CORRECT_PRODUCT_ITEM_AMOUNT":
                                operationLabel = "Termék példány mennyiség korrekció";
                                color = "#CF9FFF"
                                break;
                            default: operationLabel = ""; break;
                        }
                        return { ...log, operationLabel: operationLabel, color: color }
                    })
                    setTotalCount(response.totalElements)
                    setRows(result);
                }
            }
        );
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = event.target.value
        setRowsPerPage(event.target.value);
        localStorage.setItem("storage-logs-rows-per-page", rowsPerPage)
        setPage(0);
    };

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={9} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                rows && rows.map((row) => {
                                    return (
                                        <TableRow role="checkbox" tabIndex={-1} key={row.id} sx={{ backgroundColor: row.color }}>
                                            {columns.map((column) => {
                                                const value = column.id === 'user' ? row[column.id]['name'] : row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === "change" ? formatChange(row) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={parseInt(rowsPerPage)}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>
        </>
    );
}

function formatChange(row) {
    const obj = JSON.parse(row.change)

    if (row.operation === 'UPDATE_PRODUCT' || row.operation === 'UPDATE_PRODUCT_ITEM' || row.operation === 'CORRECT_PRODUCT_ITEM_AMOUNT') {

        const renderField = (field, label, suffix = '') => {
            return field ? <>{label} {'[' + field.originalValue + ' → ' + field.updatedValue + suffix + ']'}<br /></> : null;
        };

        return (<>
            {renderField(obj.amount, 'Mennyiség')}
            {renderField(obj.name, 'Név')}
            {renderField(obj.manufacturer, 'Gyártó')}
            {renderField(obj.centralStorageLocation, 'Központi Raktár Hely')}
            {renderField(obj.ean, 'EAN')}
            {renderField(obj.articleNumber, 'Cikkszám')}
            {renderField(obj.vtszSzjTeszor, 'Vtsz/Szj/TESZOR')}
            {renderField(obj.component, 'Komponensek')}
            {renderField(obj.stockManagement, 'Készletkezelés')}
            {renderField(obj.dataSheetUrl, 'Adatlap URL')}
            {renderField(obj.scoreSheetUrl, 'Teljesítménylap URL')}
            {renderField(obj.unitOfMeasurement, 'Mértékegység')}
            {renderField(obj.comment, 'Komment')}

            {renderField(obj.purchasePrice, 'Vásárlási Ár', ' Ft')}
            {renderField(obj.sellingPrice, 'Eladási Ár', ' Ft')}
            {renderField(obj.vat, 'ÁFA')}
            {renderField(obj.guarantee, 'Garancia')}
            {renderField(obj.expiryDate, 'Szavatosság Lejárta')}
            {renderField(obj.factoryNumber, 'Egyedi Gyári Szám')}
            {renderField(obj.packageManagement, 'Kiszerelés Kezelése')}
            {renderField(obj.minimumStockInStorage, 'Raktárankénti Minimum Készlet')}
        </>
        )
    } else {

        const renderField = (field, label, suffix = '') => {
            return field && <>{label + ": "} {field} {suffix}<br /></>
        };

        const title = <>
            {renderField(obj.centralStorageLocation, 'Központi Raktár Hely')}
            {renderField(obj.ean, 'EAN')}
            {renderField(obj.articleNumber, 'Cikkszám')}
            {renderField(obj.vtszSzjTeszor, 'Vtsz/Szj/TESZOR')}
            {renderField(obj.component, 'Komponensek')}
            {renderField(obj.stockManagement, 'Készletkezelés')}
            {renderField(obj.dataSheetUrl, 'Adatlap URL')}
            {renderField(obj.scoreSheetUrl, 'Teljesítménylap URL')}
            {renderField(obj.unitOfMeasurement, 'Mértékegység')}
            {renderField(obj.comment, 'Komment')}

            {renderField(obj.purchasePrice, 'Vásárlási Ár', ' Ft')}
            {renderField(obj.sellingPrice, 'Eladási Ár', ' Ft')}
            {renderField(obj.vat, 'ÁFA')}
            {renderField(obj.guarantee, 'Garancia')}
            {renderField(obj.expiryDate, 'Szavatosság Lejárta')}
            {renderField(obj.factoryNumber, 'Egyedi Gyári Szám')}
            {renderField(obj.packageManagement, 'Kiszerelés Kezelése')}
            {renderField(obj.minimumStockInStorage, 'Raktárankénti Minimum Készlet')}
        </>
        const showTooltip = !title.props.children.every(element => element === undefined)
        const content = <>
            {renderField(obj.name, "Név")}
            {renderField(obj.manufacturer, "Gyártó")}
            {obj.originalAmount == null ? renderField(obj.amount, "Mennyiség") : ""}
            {obj.originalAmount != null ? <>Mennyiség: {obj.updatedAmount - obj.originalAmount}<br /></> : ""}
            {obj.fromStorageOriginalAmount != null ? <>Forrás Raktár {'[' + obj.fromStorageOriginalAmount + ' → ' + obj.fromStorageUpdatedAmount + ']'} <br /></> : ""}
            {obj.originalAmount != null ? <>Cél Raktár {'[' + obj.originalAmount + ' → ' + obj.updatedAmount + ']'} <br /></> : ""}
        </>
        return (
            <>
                {showTooltip ?
                    <Tooltip title={title}> {content} </Tooltip> :
                    <>{content}</>
                }
            </>
        );

    }
}