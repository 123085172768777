import React, { useState, useEffect } from 'react';
import ProductItemEditorTemplate from './ProductItemEditorTemplate';
import productService from '../../services/ProductService';
import { useNavigate } from 'react-router-dom';

const ProductItemEditor = () => {

    const [id] = useState(window.location.pathname.split('/')[4]);

    const [productItem, setProductItem] = useState({});

    const [message, setMessage] = useState('');

    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            productService.findItemById(id, (response, error) => {
                if (response) {
                    console.log(response)
                    setProductItem(response);
                } else {
                    console.log(error);
                }
            });
        }
    }, [id]);

    const onChange = (event) => {
        const { name, value } = event.target;
        setProductItem((prevProductItem) => ({
            ...prevProductItem,
            [name]: value,
        }));
    };

    const update = (e) => {
        e.preventDefault();
        console.log(productItem)
        productService.updateProductItem(id, productItem, (response, error) => {
            if (!error) {
                navigate(-1);
            } else {
                setMessage(error === undefined ? 'Szerver nem elérhető' : error);
            }
        });
    };

    const onChangeSelectItem = (newValue, field) => {
        setProductItem((prevProductItem) => ({
          ...prevProductItem,
          [field]: newValue,
        }));
      };

    return (
        <ProductItemEditorTemplate
            data={{
                id,
                productItem,
                message,
                onChangeSelectItem,
                onChange,
                update,
            }}
        />
    );
};

export default ProductItemEditor;
