import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { GetApp, KeyboardArrowDown, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import productService from '../../services/ProductService';

const columns = [
  { id: 'name', label: 'Termék' },
  { id: 'manufacturer', label: 'Gyártó' },
  { id: 'articleNumber', label: 'Cikkszám' },
  { id: 'totalAmount', label: 'Összes Példány' }
];

export default function ProductTable() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(undefined);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalCount, setTotalCount] = React.useState(0);
  const [expanded, setExpanded] = React.useState(true);
  const [searchParams, setSearchParams] = React.useState(JSON.parse(localStorage.getItem("product-search-params")))

  useEffect(() => {
    productService.searchProducts(page, rowsPerPage, searchParams?.name, searchParams?.manufacturer, searchParams?.articleNumber,
      (response, error) => {
        if (response) {
          setTotalCount(response.totalElements)
          setRows(response.content);
        }
      }
    );
  }, [page, rowsPerPage, searchParams]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExtension = () => {
    setExpanded(!expanded);
  };

  const onSearchParamsChange = (event) => {
    const { name, value } = event.target
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [name]: value
    }))
  }

  const clear = () => {
    setSearchParams(undefined)
  }

  useEffect(() => {
    if (searchParams) localStorage.setItem("product-search-params", JSON.stringify(searchParams));
    else localStorage.removeItem("product-search-params")
  }, [searchParams]);

  const navigate = useNavigate();

  return (
    <>
      <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => handleExtension()}>
        <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
          Keresési Feltételek
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columns={20} spacing={2} padding={"10px"}>
                <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Termék Neve"
                    name="name"
                    autoComplete="off"
                    onChange={onSearchParamsChange}
                    sx={{ margin: "0px" }}
                    value={(searchParams && searchParams.name) || ""}
                  />
                </Grid>

                <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Gyártó"
                    name="manufacturer"
                    autoComplete="off"
                    onChange={onSearchParamsChange}
                    sx={{ margin: "0px" }}
                    value={(searchParams && searchParams.manufacturer) || ""}
                  />
                </Grid>

                <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Cikkszám"
                    name="articleNumber"
                    autoComplete="off"
                    onChange={onSearchParamsChange}
                    sx={{ margin: "0px" }}
                    value={(searchParams && searchParams.articleNumber) || ""}
                  />
                </Grid>

                <Grid item lg={4} md={10} xs={20}></Grid>

                <Grid item md={4} xs={20} sx={{ padding: "0px", margin: "0px 0px 10px 0px" }}>
                  <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
                </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ maxHeight: "calc(100vh - 160px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                    {column.label}
                  </TableCell>
                ))}
                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                  <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                    <GetApp fontSize="large" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={17} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                      <TableCell key="actions" padding='none' align='right'>
                        <Button color='primary' variant='contained' padding='30px'
                          sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/storage/" + row.id)}>
                          <Search />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Elemek oldalanként:"
        />
      </Paper>
    </>
  );
}
