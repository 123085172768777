import Service from "./Service"

class ProjectService extends Service {

    findById = (id, callback) => this.get("/projects/" + id, callback)

    getBudgetOfProject = (id, callback) => this.get("/projects/" + id + "/budget", callback)

    getImportantProjects = (callback) => this.get("/projects/importants", callback)

    search = (name, status, priority, page, size, callback) => this.get("/projects?page=" + page + "&size=" + size + (name != null ? "&name=" + name : "") + (status != null ? "&status=" + status : "") + (priority != null ? "&priority=" + priority : ""), callback)

    getSelectors = (callback) => this.get("/projects/selectors", callback)

    createProject = (projectRequest, callback) => this.post("/projects", projectRequest, callback)

    updateProject = (projectId, projectRequest, callback) => this.patch("/projects/" + projectId, projectRequest, callback)

    updateProjectBudget = (id, request, callback) => this.patch("/projects/" + id + "/budget", request, callback)

    deleteProject = (projectId, callback) => this.del("/projects/" + projectId, callback)
}
const projectService = new ProjectService()
export default projectService