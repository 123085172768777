import { ArrowForwardIos } from '@mui/icons-material';
import { Link } from '@mui/material';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const TitleContext = createContext();
export const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState('');

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};

export const useTitle = (pageArray) => {
  const { setTitle } = useContext(TitleContext);

  const navigate = useNavigate()

  useEffect(() => {
    if (pageArray) {
      console.log(pageArray)
      setTitle(
        <>
          {pageArray.map((item, index) => (
            <>
              {index > 0 && <ArrowForwardIos sx={{ marginTop: "12px" }} />}
              <Link onClick={() => navigate(item.url)}
                component="button"
                sx={{ padding: "0px 10px", marginTop: (pageArray.length > 1 ? "-12px": ""), color: 'white', textDecoration: 'none', ":hover": { "-webkit-transform": "scale(1.05)" } }}>
                {item.name}
              </Link>
            </>
          ))}
        </>
      );
    }
  }, [setTitle]);
};
