import axios from "axios"
import { API_URL } from "../App"
import authService from "./AuthService"

export default class Service {

	get = (url, callback) => this.handle(axios.get(API_URL + url, this.header()), callback)

	getWithParams = (url, queryParams, callback) => this.handle(axios.get(API_URL + url +  new URLSearchParams(queryParams).toString(), this.header()), callback)

	post = (url, object, callback) => this.handle(axios.post(API_URL + url, object, this.header()), callback)

	postFile = (url, object, callback) => this.handle(axios.post(API_URL + url, object, this.header("multipart/form-data")), callback)

	postWithoutToken = (url, object, callback) => this.handle(axios.post(API_URL + url, object), callback)

	put = (url, object, callback) => this.handle(axios.put(API_URL + url, object, this.header()), callback)

	patch = (url, object, callback) => this.handle(axios.patch(API_URL + url, object, this.header()), callback)

	del = (url, callback) => this.handle(axios.delete(API_URL + url, this.header()), callback)

	handle = (promise, callback) => promise
		.then(response => callback(response.data))
		.catch(error => callback(undefined, this.getErrorMessage(error)))

	getErrorMessage = (error) => {
		let msg = error.response && error.response.data
		if (msg?.code === "INVALID_TOKEN") {
			authService.logout()
			window.location.reload()
		}
		if (msg && msg.message) return msg.message
		return msg;
	}

	header = (contentType = "application/json") => {
		const user = JSON.parse(localStorage.getItem("user"))
		if (user && user.accessToken) return {
			headers:
			{
				Authorization: "Bearer " + user.accessToken,
				"Content-Type": contentType
			}
		}
		else return { headers: {} }
	}
}