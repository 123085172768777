import React, { useState, useEffect } from 'react';
import ProductEditorTemplate from './ProductEditorTemplate';
import productService from '../../services/ProductService';
import { useNavigate } from 'react-router-dom';

const ProductEditor = () => {

  const [id] = useState(window.location.pathname.split('/')[3]);
  const [product, setProduct] = useState({});
  const [message, setMessage] = useState('');
  const [triggerUpdate, setTriggerUpdate] = useState(0)
  const [waitingForServer, setWaitingForServer] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      productService.findById(id, (response, error) => {
        if (response) {
          console.log(response)
          setProduct(response);
        } else {
          console.log(error);
        }
      },
      );
    }
  }, [id, triggerUpdate]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const onChangeCheckbox = (event) => {
    const { checked, name } = event.target
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: checked,
    }));
  };

  const onChangeSelectItem = (newValue, field) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      [field]: newValue,
    }));
  };

  const update = (e) => {
    e.preventDefault();
    productService.updateProduct(id, product, (response, error) => {
      if (!error) {
        navigate(-1);
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const correctAmount = (productItemId, amount) => {
    setWaitingForServer(true)
    productService.correctProductItemAmount(productItemId, amount, (response, error) => {
      if (!error) {
        setTriggerUpdate(triggerUpdate + 1)
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
      setWaitingForServer(false)
    })
  }

  return (
    <ProductEditorTemplate
      data={{
        id,
        product,
        message,
        onChange,
        onChangeCheckbox,
        onChangeSelectItem,
        update,
        correctAmount,
        waitingForServer
      }}
    />
  );
};

export default ProductEditor;
