import { Button, Container, TextField, Alert, Grid, Divider, Autocomplete } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';

export default function ProductEditorTemplate(props) {

    const { id, onChange, update, message, productItem, onChangeSelectItem } = props.data

    const { product = {}, subStorage = {} } = props.data.productItem

    const navigate = useNavigate();

    console.log(productItem)

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={update}>
                        <Grid container spacing={2} columns={20}>

                            {createTextField("productName", product.name, "Termék Neve", onChange, false, false, true)}
                            {createTextField("productManufacturer", product.manufacturer, "Termék Gyártója", onChange, false, false, true)}
                            {createTextField("subStorageName", subStorage.name, "Alraktár", onChange, false, false, true)}
                            {createTextField("amount", productItem.amount, "Mennyiség", onChange, false, false, true)}

                            <Grid item xs={20}>
                                <Divider></Divider>
                            </Grid>

                            {createTextField("purchasePrice", productItem.purchasePrice, "Vásárlási Ár", onChange)}
                            {createTextField("sellingPrice", productItem.sellingPrice, "Eladási Ár", onChange)}

                            <Grid item md={5} xs={20} sx={{ padding: "0px" }}>
									<Autocomplete
										value={productItem.vat || ""}
										margin="normal"
										id="vat"
										label="ÁFA"
										name="vat"
										freeSolo
										autoSelect
										onChange={(event, newValue) => onChangeSelectItem(newValue, "vat")}
										options={["27%", "5%", "AAM"]}
                                        sx={{marginTop: "16px"}}
										getOptionLabel={(vat) => vat}
										renderInput={(params) => (
											<TextField {...params} label="ÁFA" />
										)}
									/>
								</Grid>

                            {createTextField("minimumStockInStorage", productItem.minimumStockInStorage, "Raktárankénti Minimum Készlet", onChange)}
                            {createTextField("packageManagement", productItem.packageManagement, "Kiszerelés kezelése", onChange)}
                            {createTextField("guarantee", productItem.guarantee || -1, "Garancia", onChange, false, false, false, "date")}
                            {createTextField("expiryDate", productItem.expiryDate || -1, "Szavatosság Lejárta", onChange, false, false, false, "date")}
                            {createTextField("factoryNumber", productItem.factoryNumber, "Egyedi Gyári Szám", onChange)}

                            {id !== undefined &&
                                <>
                                    <Grid item lg={20} md={20} xs={20}>
                                        <Divider />
                                    </Grid>

                                    {createTextField("createdAt", productItem.createdAt, "Létrehozva", onChange, false, false, true)}
                                    {createTextField("updatedAt", productItem.updatedAt, "Módosítva", onChange, false, false, true)}
                                    <Grid item lg={10} xs={20}></Grid>
                                </>

                            }

                            {message &&
                                <Grid item lg={20} xs={20}>
                                    <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                </Grid>
                            }

                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate(-1)}>
                                    Vissza
                                </Button>
                            </Grid>

                            <Grid item lg={16} md={10} xs={2}></Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="submit" fullWidth variant="contained" color="success">
                                    Mentés
                                </Button>
                            </Grid>

                        </Grid>
                    </form>
                </div>

            </Container>
        </div>
    )
}

function createTextField(name, value, label, onChange, required = false, autoFocus = false, disabled = false, type = "text") {
    return (<Grid item lg={5} md={10} xs={20}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type={type}
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value || ""}
            disabled={disabled}
        />
    </Grid>)
}