import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { Done, Edit, GetApp, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import payrollService from '../../services/PayrollService';

const columns = [
    { id: 'name', label: 'Név' },
    { id: 'totalSalaryAmount', label: 'Fizetés', align: 'right' },
    { id: 'compensationAmount', label: 'Kompenzáció', align: 'right' },
    { id: 'paid', label: 'Kifizetve', align: 'right' }
];

export default function PayrollTable() {

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2022 }, (_, index) => 2023 + index);

    const months = Array.from({ length: 12 }, (_, index) => {
        const month = new Date(2000, index, 1);
        const monthName = new Intl.DateTimeFormat('hu-HU', { month: 'long' }).format(month);
        const capitalized = monthName.charAt(0).toUpperCase() + monthName.slice(1)
        return { value: index + 1, label: capitalized };
    });

    const [year, setYear] = React.useState(localStorage.getItem("payroll-year") ?? 2024);
    const [month, setMonth] = React.useState(localStorage.getItem("payroll-month") ?? 1);
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState(undefined);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [totalCount, setTotalCount] = React.useState(0);

    useEffect(() => {
        payrollService.getPayrolls(page, rowsPerPage, year, month,
            (response, error) => {
                if (response) {
                    setTotalCount(response.totalElements)
                    setRows(response.content);
                }
            }
        );
    }, [year, month, page, rowsPerPage]);

    const changeYear = (e) => {
        const year = e.target.value
        localStorage.setItem("payroll-year", year)
        setYear(year)
    }

    const changeMonth = (e) => {
        const month = e.target.value
        localStorage.setItem("payroll-month", month)
        setMonth(month)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const navigate = useNavigate();

    return (
        <>
            <Paper style={{ marginBottom: "10px", padding: "10px", paddingRight: "25px" }}>
                <Grid container columns={20} spacing={1}>
                    <Grid item xs={10}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                            <InputLabel id="year-label">Év</InputLabel>
                            <Select
                                variant="outlined"
                                fullWidth
                                labelId="year-label"
                                name="year"
                                label="Év"
                                value={year}
                                onChange={changeYear}
                                sx={{ textAlign: "left" }}
                            >
                                {years.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                            <InputLabel id="month-label">Hónap</InputLabel>
                            <Select
                                variant="outlined"
                                fullWidth
                                labelId="month-label"
                                name="month"
                                label="Hónap"
                                value={month}
                                onChange={changeMonth}
                                sx={{ textAlign: "left" }}
                            >
                                {months.map((month) => (
                                    <MenuItem key={month.value} value={month.value}>
                                        {month.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ fontWeight: 700, background: "lightgray" }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "90px" }} align='right' padding='none'>
                                    <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                                        <GetApp fontSize="large" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows == null ? <TableRow sx={{textAlign: "center"}}> <TableCell colSpan={5} sx={{padding: "200px", textAlign: "center"}}><CircularProgress/></TableCell></TableRow> :
                                rows.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                let value;
                                                if (column.id === 'totalSalaryAmount' || column.id === 'compensationAmount') {
                                                    value = row[column.id].toLocaleString() + " Ft"
                                                } else value = row[column.id] ? row[column.id].toLocaleString() : ""
                                                return (
                                                    <TableCell key={column.id} align={column.align} padding='none' style={{ paddingLeft: "10px" }}>
                                                        {renderCellValue(column, value)}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell key="actions" padding='none' align='right' sx={{ width: "100px" }}>
                                                <Tooltip title="Módosítás">
                                                    <Button color='primary' variant='contained' padding='30px'
                                                        sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/compensations/" + row.userId + "/year/" + year + "/month/" + month)}>
                                                        <Edit />
                                                    </Button>
                                                </Tooltip>
                                                <Button color='primary' variant='contained' padding='30px'
                                                    sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/payrolls/" + row.userId + "/year/" + year + "/month/" + month)}>
                                                    <Search />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>
        </>
    );
}

const renderCellValue = (column, value) => {
    switch (column.id) {
        case "paid":
            return value === "true" ? <Done color='success' sx={{ marginRight: "30px" }} /> : value;
        default:
            return value;
    }
};