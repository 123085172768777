import React, { useEffect } from 'react';
import ProductMovingTemplate from './ProductMovingTemplate';
import subStorageService from '../../services/SubStorageService';
import { useState } from 'react';
import productService from '../../services/ProductService';

const ProductMoving = () => {

  const [storages, setStorages] = useState([]);
  const [fromStorage, setFromStorage] = useState(JSON.parse(localStorage.getItem("product-storage-from")))
  const [toStorage, setToStorage] = useState(JSON.parse(localStorage.getItem("product-storage-to")))
  const [productItems, setProductItems] = useState([]);
  const [productItem, setProductItem] = useState(JSON.parse(null));
  const [productSearchText, setProductSearchText] = useState(undefined)
  const [isNewProduct, setIsNewProduct] = useState(false)
  const [amount, setAmount] = useState(0)
  const [newProduct, setNewProduct] = useState({})
  const [open, setOpen] = React.useState(false);
  const [feedbackMessage, setFeedbackMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const [waitingForServer, setWaitingForServer] = React.useState(false)

  const resetState = () => {
    setAmount(0)
  }

  useEffect(() => {
    subStorageService.search({ onlyNonProjects: false }, (response, error) => {
      if (response) setStorages(response);
      else {
        console.log(error);
      }
    },
    );
  }, []);


  const [fromStorageItemsTable, setFromStorageItemsTable] = React.useState({
    rows: null,
    page: 0,
    rowsPerPage: 10,
    totalCount: 0
  })
  useEffect(() => {
    if(fromStorage == null) return
    productService.searchProductItemsBySubStorage({ subStorageId: fromStorage.id, page: fromStorageItemsTable.page, size: fromStorageItemsTable.rowsPerPage }, (response, error) => {
      if (response) {
        console.log(response)
        setFromStorageItemsTable((prev) => ({
          ...prev,
          rows: response.content,
          totalCount: response.totalElements
        }))
      }
      else console.log(error)
    },
    );
  }, [fromStorage, fromStorageItemsTable.page, fromStorageItemsTable.rowsPerPage]);

  const [toStorageItemsTable, setToStorageItemsTable] = React.useState({
    rows: null,
    page: 0,
    rowsPerPage: 10,
    totalCount: 0
  })
  useEffect(() => {
    if(toStorage == null) return
    productService.searchProductItemsBySubStorage({ subStorageId: toStorage.id, page: toStorageItemsTable.page, size: toStorageItemsTable.rowsPerPage }, (response, error) => {
      if (response) {
        console.log(response)
        setToStorageItemsTable((prev) => ({
          ...prev,
          rows: response.content,
          totalCount: response.totalElements
        }))
      }
      else console.log(error)
    },
    );
  }, [toStorage, toStorageItemsTable.page, toStorageItemsTable.rowsPerPage]);


  useEffect(() => {
    if (!fromStorage) return
    productService.searchProductItemsByName(productSearchText, fromStorage.id,
      (response, error) => {
        if (response) setProductItems(response.content);
        else {
          console.log(error);
        }
      }
    );
  }, [productSearchText, fromStorage, toStorage]);

  const changeProductSearchText = (text) => {
    setProductSearchText(text)
  }
  const changeStorageFrom = (storage) => {
    localStorage.setItem("product-storage-from", JSON.stringify(storage))
    setFromStorage(storage)
    setFromStorageItemsTable((prev) => ({
      ...prev,
      page: 0
    }))
    setProductItem(JSON.parse(null))
    if (storage && storage.name !== 'Bevételezés') setIsNewProduct(false)
    if (toStorage != null && storage != null && toStorage.id === storage.id) setToStorage(JSON.parse(null))
    if (!storage) {
      setProductItem(JSON.parse(null))
    }
  }
  const changeStorageTo = (storage) => {
    localStorage.setItem("product-storage-to", JSON.stringify(storage))
    setToStorageItemsTable((prev) => ({
      ...prev,
      page: 0
    }))
    setToStorage(storage)
  }
  const handleIsNewProductChange = (e) => setIsNewProduct(e.target.checked)

  const changeProduct = (item) => {
    setProductItem(item)
    if (item) setNewProduct({ ...item.product, ...item })
    else setNewProduct({})
  }

  const onChangeCheckbox = (event) => {
    const { checked, name } = event.target
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [name]: checked,
    }));
  };

  const changeAmount = (e) => {
    const a = e.target.value
    if (a < 0) return
    setAmount(a)
  }

  const onNewProductChange = (event) => {
    const { name, value } = event.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  }

  const submit = (e) => {
    e.preventDefault()
    console.log(productItem)
    if (toStorage.name === "Selejtezés") discardProductItems()
    else if (isNewProduct) createProduct()
    else moveProduct()
  }

  const createProduct = () => {
    if (!checkAmount()) return
    setWaitingForServer(true)
    if (toStorage == null) {
      sendAlertMessage("Cél raktár nincs megadva!", "error");
      return
    }
    const subStorageId = toStorage.id;
    productService.createProduct({ ...newProduct, amount, subStorageId }, (response, error) => {
      if (!error) {
        sendAlertMessage("Termék sikeresen hozzáadva!");
        resetState()
      } else {
        console.log(error)
        sendAlertMessage(error, "error");
      }
      setWaitingForServer(false)
    })
  }

  const moveProduct = () => {
    if (!checkAmount()) return
    setWaitingForServer(true)
    const request = prepareMovementRequest();
    console.log(request)
    productService.moveProduct(request, (response, error) => {
      if (!error) {
        let message = fromStorage.name === 'Bevételezés' ? "Mennyiség sikeresen hozzáadva!" : "Termék sikeresen áthelyezve!"
        sendAlertMessage(message);
        resetState()
      } else {
        console.log(error)
        sendAlertMessage(error, "error");
      }
      setWaitingForServer(false)
    })
  }

  const discardProductItems = () => {
    if (!checkAmount()) return
    setWaitingForServer(true)
    productService.discardProductItems(productItem.id, amount, (response, error) => {
      if (!error) {
        let message = "Termékek sikeresen leselejtezve!"
        sendAlertMessage(message);
        resetState()
      } else {
        console.log(error)
        sendAlertMessage(error, "error");
      }
      setWaitingForServer(false)
    })
  }

  const checkAmount = () => {
    if (amount <= 0) {
      sendAlertMessage("Mennyiség pozitív érték kell legyen!", "error")
      return false
    }
    return true
  }

  const prepareMovementRequest = () => {
    return ({
      ...newProduct,
      amount,
      subStorageId: toStorage.id,
      fromSubStorageId: fromStorage.id,
      productItemId: productItem.id
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const sendAlertMessage = (text, type = 'success') => {
    setFeedbackMessage(text)
    setAlertType(type)
    setOpen(true)
  }

  const onChangeSelectItem = (newValue, field) => {
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [field]: newValue,
    }));
  };

  const handleFromStorageChangePage = (event, newPage) => {
    setFromStorageItemsTable((prev) => ({
      ...prev,
      page: newPage
    }))
  };

  const handleFromStorageChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value
    setFromStorageItemsTable((prev) => ({
      ...prev,
      rowsPerPage: rowsPerPage,
      page: 0
    }))
  };

  const handleToStorageChangePage = (event, newPage) => {
    setToStorageItemsTable((prev) => ({
      ...prev,
      page: newPage
    }))
  };

  const handleToStorageChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value
    setToStorageItemsTable((prev) => ({
      ...prev,
      rowsPerPage: rowsPerPage,
      page: 0
    }))
  };

  return (
    <ProductMovingTemplate
      data={{
        storages,
        fromStorage,
        productItem,
        productItems,
        amount,
        isNewProduct,
        newProduct,
        toStorage,
        waitingForServer,
        setProductItems,
        handleIsNewProductChange,
        changeStorageFrom,
        changeStorageTo,
        changeProduct,
        changeAmount,
        changeProductSearchText,
        onNewProductChange,
        onChangeCheckbox,
        onChangeSelectItem,
        submit,
        open,
        handleClose,
        feedbackMessage,
        alertType,
        fromStorageItemsTable,
        toStorageItemsTable,
        handleFromStorageChangePage,
        handleFromStorageChangeRowsPerPage,
        handleToStorageChangePage,
        handleToStorageChangeRowsPerPage
      }}
    />
  );
};

export default ProductMoving;
