import Service from "./Service"

class ProductService extends Service {

    findById = (id, callback) => this.get("/products/" + id, callback)

    findItemById = (id, callback) => this.get("/products/items/" + id, callback)

    searchProducts = (page, size, name, manufacturer, articleNumber, callback) => this.get("/products?page=" + page + "&size=" + size + (name ? "&name=" + name : "") + (manufacturer ? "&manufacturer=" + manufacturer : "") + (articleNumber ? "&articleNumber=" + articleNumber : ""), callback)

    searchProductItemsByName = (searchText, subStorageId, callback) => this.get("/products/items?page=0&size=5" + (searchText ? "&searchText=" + searchText : "") + (subStorageId ? "&subStorageId=" + subStorageId : ""), callback)

    searchProductItemsBySubStorage = (request, callback) => this.getWithParams("/products/items?", request, callback)

    createProduct = (product, callback) => this.post("/products", product, callback)

    updateProduct = (id, product, callback) => this.patch("/products/" + id, product, callback)

    updateProductItem = (id, productItem, callback) => this.patch("/products/items/" + id, productItem, callback)

    discardProductItems = (id, amount, callback) => this.post("/products/items/" + id + "/discard", { amount }, callback)

    correctProductItemAmount = (id, amount, callback) => this.post("/products/items/" + id + "/corrections", { amount }, callback)

    moveProduct = (product, callback) => this.patch("/products", product, callback)
}
const productService = new ProductService()
export default productService