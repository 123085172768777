import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Badge, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { Add, Delete, Done, Download, Edit, GetApp, KeyboardArrowDown, PriorityHigh, Upload } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import tasklogService from '../../services/TaskLogService';
import { nonProjects } from '../../util/Const';
import userService from '../../services/UserService';
import projectService from '../../services/ProjectService';
import taskTypeService from '../../services/TaskTypeService';
import ConfirmationDialog from '../common/ConfirmationDialog';

const mainColumns = [
    { id: 'date', label: 'Dátum' },
    { id: 'username', label: 'Név' },
    { id: 'lunchBreak', label: 'E.Sz.', align: "center", toolTipText: "Ebéd Szünet" },
    { id: 'lateness', label: 'Késés', align: "center" },
];

const subColumns = [
    { id: 'projectName', label: 'Projekt' },
    { id: 'projectPriority', label: '', toolTipText: "Prioritás" },
    { id: 'taskTypeName', label: 'Feladat Típus' },
    { id: 'location', label: 'Helyszín' },
    { id: 'startTime', label: 'Kezdés' },
    { id: 'endTime', label: 'Befejezés' },
    { id: 'licensePlate', label: 'Jármű' },
    { id: 'teamLeader', label: 'CS.V.', align: "center", toolTipText: "Csoport Vezető" },
    { id: 'fuelCost', label: 'Üzemanyag' },
    { id: 'odometer', label: 'Kilométeróra' },
    { id: 'comment', label: 'Megjegyzés' }
];

export default function TaskLogTable() {
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState(undefined);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem("task-log-rows-per-page") || 5);
    const [totalCount, setTotalCount] = React.useState(0);
    const [expanded, setExpanded] = React.useState(false);
    const [metadata, setMetadata] = React.useState({});
    const [searchParams, setSearchParams] = React.useState(JSON.parse(localStorage.getItem("task-log-search-params")) || {})
    const [dialogState, setDialogState] = React.useState({ open: false, object: null });

    const handleDeletionClick = (object) => {
        setDialogState({ open: true, object: object });
    };

    const handleClose = () => {
        setDialogState({ open: false, object: null });
    }

    useEffect(() => {
        retrieveList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchParams]);

    function retrieveList() {
        setRows(null)
        tasklogService.search(buildQueryParams(), (response, error) => {
            if (response) {
                if (response.taskLogs.content.length === 0 && page !== 0) {
                    setPage(0)
                    return
                }
                response.taskLogs.content.forEach(taskLog => {
                    taskLog.taskLogItems = taskLog.taskLogItems.filter(i => i.projectName !== 'Befejezés')
                })
                setTotalCount(response.taskLogs.totalElements)
                setRows(response.taskLogs.content);
                setMetadata(response.taskLogHours)
            } else console.log(error)
        });
    }

    function buildQueryParams() {
        return {
            page: page,
            size: rowsPerPage,
            ...(searchParams.from && { from: searchParams.from }),
            ...(searchParams.to && { to: searchParams.to }),
            ...(searchParams.user && { userId: searchParams.user.id }),
            ...(searchParams.project && { projectId: searchParams.project.id }),
            ...(searchParams.taskType && { taskTypeId: searchParams.taskType.id }),
            ...(searchParams.projectStatus && { projectStatus: searchParams.projectStatus }),
            ...(searchParams.projectPriority && { projectPriority: searchParams.projectPriority }),
        }
    }

    const [users, setUsers] = React.useState([]);
    useEffect(() => {
        userService.getSelectors(
            (response, error) => {
                if (response) setUsers(response);
                else console.log(error);
            },
        );
    }, []);

    const [projects, setProjects] = React.useState([]);
    useEffect(() => {
        projectService.getSelectors(
            (response, error) => {
                if (response) setProjects(response);
                else console.log(error);
            },
        );
    }, []);

    const [taskTypes, setTaskTypes] = React.useState([]);
    useEffect(() => {
        taskTypeService.search(
            (response, error) => {
                if (response) setTaskTypes(response);
                else console.log(error);
            },
        );
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const rowsPerPage = event.target.value
        setRowsPerPage(event.target.value);
        localStorage.setItem("task-log-rows-per-page", rowsPerPage)
        setPage(0);
    };

    const handleExtension = () => {
        setExpanded(!expanded);
    };

    const onSearchParamsChange = (name, value) => {
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            [name]: value
        }))
    }

    const clear = () => {
        setSearchParams({})
    }

    useEffect(() => {
        if (searchParams) localStorage.setItem("task-log-search-params", JSON.stringify(searchParams));
        else localStorage.removeItem("task-log-search-params")
    }, [searchParams]);

    const navigate = useNavigate();

    return (
        <>
            <Accordion style={{ marginBottom: "10px" }} disableGutters expanded={expanded} onChange={() => handleExtension()}>
                <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ marginRight: "0px", height: "2em", width: "auto" }} />} sx={{ minHeight: "0px", fontSize: "24px", fontWeight: "bold", backgroundColor: "darkgray", borderRadius: "4px 4px 0px 0px", paddingLeft: "20px", margin: "0" }}>
                    Keresési Feltételek
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columns={20} spacing={2} padding={"10px"}>
                        <Grid item lg={16} md={20} xs={30}>
                            <Grid container columns={16} spacing={2} padding={"0px"}>
                                <Grid item lg={4} md={20} xs={30} sx={{ padding: "0px" }}>
                                    <Autocomplete
                                        value={(searchParams && searchParams.user) || null}
                                        margin="normal"
                                        id="user"
                                        onChange={(event, newValue) => onSearchParamsChange("user", newValue)}
                                        options={users}
                                        getOptionLabel={(user) => user.name}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Munkavállaló" />
                                        )}
                                    />
                                </Grid>

                                <Grid item lg={4} md={20} xs={30} sx={{ padding: "0px" }}>
                                    <Autocomplete
                                        value={(searchParams && searchParams.project) || null}
                                        margin="normal"
                                        id="project"
                                        onChange={(event, newValue) => onSearchParamsChange("project", newValue)}
                                        options={projects}
                                        getOptionLabel={(project) => project.name}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Projekt" />
                                        )}
                                    />
                                </Grid>

                                <Grid item lg={4} md={20} xs={30} sx={{ padding: "0px" }}>
                                    <Autocomplete
                                        value={(searchParams && searchParams.taskType) || null}
                                        margin="normal"
                                        id="taskType"
                                        onChange={(event, newValue) => onSearchParamsChange("taskType", newValue)}
                                        options={taskTypes}
                                        getOptionLabel={(taskType) => taskType.name}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Feladat" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={4} sx={{ padding: "0px", margin: "0px" }}></Grid>

                                <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        type="date"
                                        label="-tól"
                                        autoComplete="off"
                                        onChange={(event) => onSearchParamsChange("from", event.target.value)}
                                        sx={{ margin: "0px" }}
                                        value={(searchParams && searchParams.from) || "-1"}
                                    />
                                </Grid>

                                <Grid item lg={4} md={10} xs={20} sx={{ padding: "0px" }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        type="date"
                                        label="-ig"
                                        autoComplete="off"
                                        onChange={(event) => onSearchParamsChange("to", event.target.value)}
                                        sx={{ margin: "0px" }}
                                        value={(searchParams && searchParams.to) || "-1"}
                                    />
                                </Grid>

                                <Grid item lg={4} md={20} xs={30} sx={{ padding: "0px" }}>
                                    <FormControl sx={{ marginLeft: "0px", marginTop: "0px" }} fullWidth>
                                        <InputLabel id="project-status-label">Státusz</InputLabel>
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            labelId="project-status-label"
                                            label="Státusz"
                                            value={(searchParams && searchParams.projectStatus) || null}
                                            onChange={(event) => onSearchParamsChange("projectStatus", event.target.value)}
                                            sx={{ padding: "0px" }}
                                        >
                                            <MenuItem value={null}>Összes</MenuItem>
                                            <MenuItem value={"UNDER_DISCUSSION"}>Megbeszélés tárgya</MenuItem>
                                            <MenuItem value={"PLANNING"}>Tervezés alatt</MenuItem>
                                            <MenuItem value={"UNDER_EXECUTION"}>Kivetelezés alatt</MenuItem>
                                            <MenuItem value={"OVERDUE"}>Csúszásban</MenuItem>
                                            <MenuItem value={"MAINTENANCE"}>Karbantartás</MenuItem>
                                            <MenuItem value={"DONE"}>Befejezve</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={4} md={20} xs={30} sx={{ padding: "0px" }}>
                                    <FormControl sx={{ marginLeft: "0px", marginTop: "0px" }} fullWidth>
                                        <InputLabel id="project-status-label">Prioritás</InputLabel>
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            labelId="project-priority-label"
                                            name="projectPriority"
                                            label="Prioritás"
                                            value={(searchParams && searchParams.projectPriority) || null}
                                            onChange={(event) => onSearchParamsChange("projectPriority", event.target.value)}
                                            sx={{ textAlign: "left" }}
                                        >
                                            <MenuItem value={null}>Összes</MenuItem>
                                            <MenuItem value={"EXTRA_IMPORTANT"}>Extra fontos</MenuItem>
                                            <MenuItem value={"IMPORTANT"}>Fontos</MenuItem>
                                            <MenuItem value={"NORMAL"}>Normál</MenuItem>
                                            <MenuItem value={"NOT_IMPORTANT"}>Nem fontos</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item md={4} xs={20} sx={{ padding: "0px", margin: "0px 0px 10px 0px" }}>
                                    <Button onClick={clear} variant="contained" fullWidth style={{ height: "56px", margin: "0px" }}>Szűrők Törlése</Button>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={20} xs={30}>
                            <Grid container columns={20} spacing={1} padding={"0px"}>

                                <Grid item xs={20} sx={{ padding: "0px" }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        type="text"
                                        label="Programozás/Tervezés"
                                        autoComplete="off"
                                        InputProps={{ style: { pointerEvents: 'none', backgroundColor: "Gainsboro" } }}
                                        sx={{ margin: "0px" }}
                                        value={formatTime(metadata.programmingHours)}
                                    />
                                </Grid>

                                <Grid item xs={20} sx={{ padding: "0px" }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        type="text"
                                        label="Utazás"
                                        autoComplete="off"
                                        InputProps={{ style: { pointerEvents: 'none', backgroundColor: "Gainsboro" } }}
                                        sx={{ margin: "0px" }}
                                        value={formatTime(metadata.travellingHours)}
                                    />
                                </Grid>

                                <Grid item xs={20} sx={{ padding: "0px" }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        type="text"
                                        label="Egyéb"
                                        autoComplete="off"
                                        InputProps={{ style: { pointerEvents: 'none', backgroundColor: "Gainsboro" } }}
                                        sx={{ margin: "0px" }}
                                        value={formatTime(metadata.otherHours)}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer style={{ maxHeight: "calc(100vh - " + (expanded ? "500" : "240") + "px)" }}>
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, key) => (
                                    <Tooltip title={column.toolTipText}>
                                        <TableCell key={key} align={column.align} style={{ fontWeight: 700, background: "darkgray" }}>
                                            <>{column.label}</>
                                        </TableCell>
                                    </Tooltip>
                                ))}
                                <TableCell key="actions" style={{ fontWeight: 700, background: "darkgray", minWidth: "97px" }} align='right' padding='none'>
                                    <Button variant='contained' color='warning' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => console.log("EXPORT")}>
                                        <GetApp fontSize="large" />
                                    </Button>
                                    <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/task-logs/create")}>
                                        <Add fontSize="large" />
                                    </Button>
                                </TableCell>
                                <TableCell style={{ fontWeight: 700, background: "darkgray" }}>Számla</TableCell>
                                {subColumns.map((column, key) => (
                                    <Tooltip title={column.toolTipText}>
                                        <TableCell key={key} align={column.align} style={{ fontWeight: 700, background: "darkgray" }}>
                                            <>{column.label}</>
                                        </TableCell>
                                    </Tooltip>
                                ))}


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows == null ? <TableRow sx={{ textAlign: "center" }}> <TableCell colSpan={17} sx={{ padding: "200px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                rows.map((row, key) => {
                                    const last = row.taskLogItems[row.taskLogItems.length - 1]
                                    const isNotFinished = last === undefined || (last.endTime == null && last.taskTypeName !== null && last.taskTypeName !== 'Szállás')
                                    return (
                                        <React.Fragment key={key}>
                                            {row.taskLogItems.map((item, key) => (
                                                <TableRow hover role="checkbox" key={key} style={{ whiteSpace: 'nowrap', backgroundColor: isNotFinished ? "orange" : "" }}>
                                                    {key === 0 && mainColumns.map((column, key) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={key} align={column.align} rowSpan={row.taskLogItems.length}>
                                                                {renderCellValue(column, value)}
                                                            </TableCell>
                                                        );
                                                    })}
                                                    {key === 0 &&
                                                        <TableCell rowSpan={row.taskLogItems.length} key="actions" padding='none' align='right'>
                                                            <Button color='primary' variant='contained' padding='30px'
                                                                sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/task-logs/" + row.id)}>
                                                                <Edit />
                                                            </Button>
                                                            <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                                onClick={() => handleDeletionClick(row)}>
                                                                <Delete />
                                                            </Button>
                                                        </TableCell>
                                                    }
                                                    <TableCell style={{ fontWeight: 700, minWidth: "90px", backgroundColor: isNotFinished ? "orange" : determineRowColor(item.projectName, item.taskTypeName) }} align='center' padding='none'>
                                                        <Button variant='contained' sx={{ minWidth: "0px", padding: "0px" }} onClick={() => navigate("/dashboard/task-logs/" + row.id + "/task-log-items/" + item.id + "/invoices")}>
                                                            <Badge badgeContent={item.invoiceCount} color="secondary">
                                                                <Upload fontSize="large" />
                                                            </Badge>
                                                        </Button>
                                                    </TableCell>
                                                    {subColumns.map((column, key) => {
                                                        const value = item[column.id];
                                                        return (
                                                            <TableCell key={key} align={column.align} style={{ backgroundColor: isNotFinished ? "orange" : determineRowColor(item.projectName, item.taskTypeName) }}>
                                                                {renderCellValue(column, value)}
                                                            </TableCell>
                                                        )
                                                    })}


                                                </TableRow>
                                            ))}
                                        </React.Fragment>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>

            <ConfirmationDialog
                title={"Biztosan törölni szeretnéd ezt a munkaidőbejegyzést?"}
                content={
                    <>
                        {dialogState.object?.username} <br />
                        {dialogState.object?.date}
                    </>
                }
                open={dialogState.open}
                onClose={handleClose}
                onConfirm={() => {
                    setRows(null)
                    tasklogService.deleteTaskLog(dialogState.object?.id, (response, error) => { if (!error) retrieveList() })
                }}
            />
        </>
    );
}

const renderCellValue = (column, value) => {
    switch (column.id) {
        case "teamLeader":
        case "lunchBreak":
        case "lateness":
            return value === true ? <Done color='success' /> : value;
        case "projectPriority":
            return value === "EXTRA_IMPORTANT" ? <Tooltip title={"Extra Fontos"}><PriorityHigh color='error' /></Tooltip> : ""
        case "hasAccommodationInvoice":
        case "hasFuelInvoice":
            return value === true ? (
                <Button component="label" variant="contained" style={{ minWidth: 0, padding: "0px" }}>
                    <Download fontSize='large' />
                </Button>
            ) : value;
        case "odometer":
            return value === null ? "" : value.toLocaleString()
        case "comment":
            return truncateString(value, 50)
        default:
            return value;
    }
};

const determineRowColor = (projectName, taskTypeName) => {

    const travel = ['Jármű Vezető', 'Jármű Utas']
    if (nonProjects.includes(projectName)) return '#fffdaf'
    if (travel.includes(taskTypeName)) return 'lightgray'
    return 'white'
}

const truncateString = (str, maxLength) => {
    if (!str) return ""
    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
    } else {
        return str;
    }
}

function formatTime(seconds) {
    if (seconds === 0) return '-'
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return (hours > 0 ? (hours + ' óra ') : "") + (minutes > 0 ? (minutes + ' perc') : "")
}