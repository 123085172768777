import Service from "./Service"

class UserService extends Service {

    search = (queryParams, callback) => this.getWithParams("/users?", queryParams, callback)

    getSelectors = (callback) => this.get("/users/selectors", callback)

    findById = (id, callback) => this.get("/users/" + id, callback)

    register = (user, callback) => this.post("/users", user, callback)

    updateUser = (userId, userUpdateRequest, callback) => this.patch("/users/" + userId, userUpdateRequest, callback)
}
const userService = new UserService()
export default userService