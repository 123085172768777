import { Button, Container, TextField, Alert, Grid, Divider, MenuItem, Select, InputLabel, FormControl, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom';

export default function VehicleEditorTemplate(props) {

	const { id, onChange, create, update, message } = props.data

	const { licensePlate, technicalValidity, odometer, primaryHighwayVignetteCategory = "", primaryHighwayVignetteValidity = "", primaryHighwayVignetteExpiryDate,
		secondaryHighwayVignetteCategory = "", secondaryHighwayVignetteValidity = "", secondaryHighwayVignetteExpiryDate, insuranceCompanyName = "", insuranceValidity,
		lastEngineOilChange, createdAt, updatedAt } = props.data.vehicle

	const navigate = useNavigate();

	console.log(props.data)

	return (
		<div>
			<Container component="main">
				<div className="register-paper">
					<form id="login" onSubmit={id ? update : create}>
						<Grid container spacing={2} columns={20}>
							{createTextField("licensePlate", "Rendszám", licensePlate, onChange, true, true)}
							{createTextField("lastEngineOilChange", "Utolsó Olajcsere", lastEngineOilChange, onChange)}
							{createTextField("odometer", "Kilométer óra állása", odometer, onChange)}


							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"technicalValidity"}
									label={"Műszaki Érvényesség Lejárta"}
									name={"technicalValidity"}
									type="date"
									autoComplete="off"
									onChange={onChange}
									value={technicalValidity || "-1"}
								/>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<Typography style={{ marginTop: "30px", marginRight: "30px", textAlign: "right" }}>Elsődleges Autópálya Matrica</Typography>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<FormControl sx={{ minWidth: 120, marginTop: "16px" }} fullWidth>
									<InputLabel id="category">Kategória</InputLabel>
									<Select
										variant="outlined"
										fullWidth
										id="primaryHighwayVignetteCategory"
										labelId="category"
										name="primaryHighwayVignetteCategory"
										label="Kategória"
										value={primaryHighwayVignetteCategory}
										onChange={onChange}
										sx={{ textAlign: "left" }}
									>
										<MenuItem value={undefined} style={{ color: "darkgray", fontStyle: "italic" }}>Nincs</MenuItem>
										<MenuItem value={"D1"}>D1</MenuItem>
										<MenuItem value={"D2"}>D2</MenuItem>
										<MenuItem value={"U"}>U</MenuItem>
										<MenuItem value={"D1M"}>D1M</MenuItem>
										<MenuItem value={"B2"}>B2</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<FormControl sx={{ minWidth: 120, marginTop: "16px" }} fullWidth>
									<InputLabel id="validity">Érvényesség</InputLabel>
									<Select
										variant="outlined"
										fullWidth
										id="primaryHighwayVignetteValidity"
										labelId="validity"
										name="primaryHighwayVignetteValidity"
										label="Érvényesség"
										value={primaryHighwayVignetteValidity}
										onChange={onChange}
										sx={{ textAlign: "left" }}
									>
										<MenuItem value={undefined} style={{ color: "darkgray", fontStyle: "italic" }}>Nincs</MenuItem>
										<MenuItem value={"Heti"}>Heti</MenuItem>
										<MenuItem value={"Havi"}>Havi</MenuItem>
										<MenuItem value={"Éves Vármegyei"}>Éves Vármegyei</MenuItem>
										<MenuItem value={"Éves Országos"}>Éves Országos</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"primaryHighwayVignetteExpiryDate"}
									label={"Lejárati Dátum"}
									name={"primaryHighwayVignetteExpiryDate"}
									type="date"
									autoComplete="off"
									onChange={onChange}
									value={primaryHighwayVignetteExpiryDate ? primaryHighwayVignetteExpiryDate : "-1"}
								/>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<Typography style={{ marginTop: "30px", marginRight: "30px", textAlign: "right" }}>Másodlagos Autópálya Matrica</Typography>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<FormControl sx={{ minWidth: 120, marginTop: "16px" }} fullWidth>
									<InputLabel id="category">Kategória</InputLabel>
									<Select
										variant="outlined"
										fullWidth
										labelId="category"
										id="secondaryHighwayVignetteCategory"
										name="secondaryHighwayVignetteCategory"
										label="Kategória"
										value={secondaryHighwayVignetteCategory}
										onChange={onChange}
										sx={{ textAlign: "left" }}
									>
										<MenuItem value={undefined} style={{ color: "darkgray", fontStyle: "italic" }}>Nincs</MenuItem>
										<MenuItem value={"D1"}>D1</MenuItem>
										<MenuItem value={"D2"}>D2</MenuItem>
										<MenuItem value={"U"}>U</MenuItem>
										<MenuItem value={"D1M"}>D1M</MenuItem>
										<MenuItem value={"B2"}>B2</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<FormControl sx={{ minWidth: 120, marginTop: "16px" }} fullWidth>
									<InputLabel id="validity">Érvényesség</InputLabel>
									<Select
										variant="outlined"
										fullWidth
										id="secondaryHighwayVignetteValidity"
										labelId="validity"
										name="secondaryHighwayVignetteValidity"
										label="Érvényesség"
										value={secondaryHighwayVignetteValidity}
										onChange={onChange}
										sx={{ textAlign: "left" }}
									>
										<MenuItem value={undefined} style={{ color: "darkgray", fontStyle: "italic" }}>Nincs</MenuItem>
										<MenuItem value={"Heti"}>Heti</MenuItem>
										<MenuItem value={"Havi"}>Havi</MenuItem>
										<MenuItem value={"Éves Vármegyei"}>Éves Vármegyei</MenuItem>
										<MenuItem value={"Éves Országos"}>Éves Országos</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"secondaryHighwayVignetteExpiryDate"}
									label={"Lejárati Dátum"}
									name={"secondaryHighwayVignetteExpiryDate"}
									type="date"
									autoComplete="off"
									onChange={onChange}
									value={secondaryHighwayVignetteExpiryDate ? secondaryHighwayVignetteExpiryDate : "-1"}
								/>
							</Grid>

							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"insuranceCompanyName"}
									label={"Biztosító Cég Neve"}
									name={"insuranceCompanyName"}
									type="text"
									autoComplete="off"
									onChange={onChange}
									value={insuranceCompanyName}
								/>
							</Grid>


							<Grid item lg={5} md={10} xs={20}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id={"insuranceValidity"}
									label={"Biztosítás Lejárati Dátuma"}
									name={"insuranceValidity"}
									type="date"
									autoComplete="off"
									onChange={onChange}
									value={insuranceValidity ? insuranceValidity : "-1"}
								/>
							</Grid>

							{id !== undefined &&
								<>
									<Grid item lg={20} md={20} xs={20}>
										<Divider />
									</Grid>

									{createTextField("createdAt", "Létrehozva", createdAt, onChange, false, false, true)}
									{createTextField("updatedAt", "Módosítva", updatedAt, onChange, false, false, true)}
								</>

							}



							{message &&
								<Grid item lg={20} xs={20}>
									<Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
								</Grid>
							}

							<Grid item lg={20} md={20} xs={20}></Grid>
							<Grid item lg={2} md={5} xs={9}>
								<Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate("/dashboard/vehicles")}>
									Vissza
								</Button>
							</Grid>

							<Grid item lg={16} md={10} xs={2}></Grid>

							<Grid item lg={2} md={5} xs={9}>
								<Button type="submit" fullWidth variant="contained" color="success">
									Mentés
								</Button>
							</Grid>

						</Grid>
					</form>
				</div>

			</Container>
		</div>
	)
}

function createTextField(name, label, value, onChange, required = false, autoFocus = false, disabled = false) {
	return (<Grid item lg={5} md={10} xs={20}>
		<TextField
			variant="outlined"
			margin="normal"
			required={required}
			fullWidth
			id={name}
			label={label}
			name={name}
			type="text"
			autoFocus={autoFocus}
			autoComplete="off"
			onChange={onChange}
			value={value}
			disabled={disabled}
		/>
	</Grid>)
}