import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import noteService from '../../services/NoteService';
import NoteEditorTemplate from './NoteEditorTemplate';

const NoteEditor = () => {

  const [id] = useState(
    window.location.pathname.split('/')[3] === 'create'
      ? undefined
      : window.location.pathname.split('/')[3]
  );

  const [note, setNote] = useState({
    text: "",
  });

  const [message, setMessage] = useState('');

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      noteService.findById(
        id,
        (response, error) => {
          if (response) {
            console.log(response)
            setNote((prevNote) => ({
              ...prevNote,
              ...response,
            }));
          } else {
            console.log(error);
          }
        },
      );
    }
  }, [id]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setNote((prevNote) => ({
      ...prevNote,
      [name]: value,
    }));
  };

  const create = (e) => {
    e.preventDefault();
    noteService.createNote(note, (response, error) => {
      if (!error) {
        navigate('/dashboard/notes');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    noteService.updateNote(id, note, (response, error) => {
      if (!error) {
        navigate('/dashboard/notes');
      } else {
        setMessage(error === undefined ? 'Szerver nem elérhető' : error);
      }
    });
  };

  return (
    <NoteEditorTemplate
      data={{
        id,
        note,
        message,
        onChange,
        create,
        update,
      }}
    />
  );
};

export default NoteEditor;
