import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Collapse } from '@mui/material';
import { AccessTime, AccountBalance, Assignment, AttachMoney, Ballot, BarChart, ExpandLess, ExpandMore, Feed, FormatListNumbered, Group, Handyman, History, LocalShipping, MoveDown, Receipt, Settings, SpeakerNotes, Storage, Warehouse } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


export function MainListItems() {

  const navigate = useNavigate();

  const [openStorage, setOpenStorage] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const url = window.location.pathname

  const selectedMenuItem = { backgroundColor: "gray", color: "white" }

  console.log(url)

  return (
    <React.Fragment>

      <ListItemButton onClick={() => navigate("/dashboard/task-logs")} sx={url.startsWith("/dashboard/task-logs") ? selectedMenuItem : {}}>
        <ListItemIcon>
          <AccessTime />
        </ListItemIcon>
        <ListItemText primary="Munkaidő" />
      </ListItemButton>

      <ListItemButton onClick={() => setOpenStorage(!openStorage)}>
        <ListItemIcon>
          <Warehouse />
        </ListItemIcon>
        <ListItemText primary="Raktár" />
        {openStorage ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={openStorage} timeout="auto" unmountOnExit>
        <React.Fragment>
          <ListItemButton onClick={() => navigate("/dashboard/storage")} sx={/^\/dashboard\/storage(\/(\d+|item\/\d+))?$/.test(url) ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <Handyman />
            </ListItemIcon>
            <ListItemText primary="Termékek" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/dashboard/storage/move")} sx={url.startsWith("/dashboard/storage/move") ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <MoveDown />
            </ListItemIcon>
            <ListItemText primary="Mozgatás" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/dashboard/storage-logs")} sx={url.startsWith("/dashboard/storage-logs") ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <History />
            </ListItemIcon>
            <ListItemText primary="Történet" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/dashboard/storage/substorages")} sx={url.startsWith("/dashboard/storage/substorages") ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <Storage />
            </ListItemIcon>
            <ListItemText primary="Alraktárak" />
          </ListItemButton>
        </React.Fragment>
      </Collapse>

      <ListItemButton onClick={() => setOpenSettings(!openSettings)}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Beállítások" />
        {openSettings ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <React.Fragment>
          <ListItemButton onClick={() => navigate("/dashboard/projects")} sx={/^\/dashboard\/projects(\/\d+)?$/.test(url) ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Projektek" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/dashboard/project-tasks")} sx={url.startsWith("/dashboard/project-tasks") ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <FormatListNumbered />
            </ListItemIcon>
            <ListItemText primary="Projekt Feladatok" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/dashboard/task-types")} sx={url.startsWith("/dashboard/task-types") ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <Ballot />
            </ListItemIcon>
            <ListItemText primary="Feladatkörök" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/dashboard/users")} sx={url.startsWith("/dashboard/users") ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <Group />
            </ListItemIcon>
            <ListItemText primary="Felhasználók" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/dashboard/vehicles")} sx={url.startsWith("/dashboard/vehicles") ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <LocalShipping />
            </ListItemIcon>
            <ListItemText primary="Járművek" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/dashboard/global-payment-modifiers")} sx={url.startsWith("/dashboard/global-payment-modifiers") ? selectedMenuItem : {}}>
            <ListItemIcon style={{ marginLeft: "20px" }}>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText primary="Globális Értékek" />
          </ListItemButton>
        </React.Fragment>
      </Collapse>

      <ListItemButton onClick={() => navigate("/dashboard/payrolls")} sx={url.startsWith("/dashboard/payrolls") ? selectedMenuItem : {}}>
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        <ListItemText primary="Fizetések" />
      </ListItemButton>

      <ListItemButton onClick={() => { navigate("/dashboard/invoices") }} sx={url.startsWith("/dashboard/invoices") ? selectedMenuItem : {}}>
        <ListItemIcon>
          <Receipt />
        </ListItemIcon>
        <ListItemText primary="Számlák" />
      </ListItemButton>

      <ListItemButton onClick={() => { navigate("/dashboard/notes") }} sx={url.startsWith("/dashboard/notes") ? selectedMenuItem : {}}>
        <ListItemIcon>
          <SpeakerNotes />
        </ListItemIcon>
        <ListItemText primary="Jegyzetek" />
      </ListItemButton>

    </React.Fragment>)
}