import { Add, Delete, Edit } from "@mui/icons-material";
import { Button, Container, TextField, Alert, Grid, Divider, Autocomplete, FormControl, InputLabel, Select, MenuItem, Box, Chip, OutlinedInput, CircularProgress, Hidden, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link, Dialog, DialogTitle, Typography, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from "react"
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from "../common/ConfirmationDialog";
import PercentageProgressBar from "../common/PercentageProgressBar";

const columns = [
    { id: 'description', label: 'Leírás' },
    { id: 'progressLevel', label: 'Készültség' },
    { id: 'priority', label: 'Prioritás' },
    { id: 'status', label: 'Státusz' },
    { id: 'weight', label: 'Feladat Súlya' },
    { id: 'condition', label: 'Feltétel' },
    { id: 'deadline', label: 'Határidő' },
    { id: 'scheduleDateStart', label: 'Tervezett Kezdés' },
    { id: 'scheduleDateEnd', label: 'Tervezett Befejezés' },
    { id: 'vehicle', subId: 'licensePlate', label: 'Jármű' },
    { id: 'users', subId: 'name', label: 'Munkavállalók' },
    { id: 'updatedAt', label: 'Módosítva' }
];

export default function ProjectTaskEditorTemplate(props) {

    const { id, onChange, setDate, deleteSubTask, onUsersChange, onChangeSelectItem, projects, vehicles, taskTypes, users, create, update, message,
        projectTask, waitingForServer, copyProjectTask } = props.data

    const navigate = useNavigate();
    const [dialogState, setDialogState] = React.useState({ open: false, object: null });

    const handleClickOpen = (object) => {
        setDialogState({ open: true, object: object });
    };

    const handleClose = () => {
        setDialogState({ open: false, object: null });
    };

    const [copyDialogState, setCopyDialogState] = React.useState({ open: false, object: null });

    const handleCopyClickOpen = (object) => {
        setCopyDialogState({ open: true, object: object });
    };

    const handleCopyClose = () => {
        setCopyDialogState((prevState) => ({
            ...prevState,
            open: false
        }));
    };

    const changeCopyProjectTarget = (newValue) => {
        setCopyDialogState((prevState) => ({
            ...prevState,
            project: newValue
        }));
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
            },
        },
    };

    function getStyles(name, users) {
        if (users.map(user => user.name).indexOf(name) === -1) return
        return {
            backgroundColor: "#2e7d32",
            color: "white"
        };
    }

    return (
        <div>
            <Container component="main">
                <div className="register-paper">
                    <form id="login" onSubmit={id ? update : create}>
                        <Grid container spacing={2} columns={20}>
                            {id != null && projectTask.project == null ? <Grid item xs={20}><CircularProgress sx={{ margin: "100px" }} /> </Grid> :
                                <>
                                    <Grid item xl={5} lg={10} md={10} xs={20}>
                                        <Autocomplete
                                            value={projectTask.project || null}
                                            margin="normal"
                                            label="Projekt"
                                            autoHighlight
                                            autoSelect
                                            disabled={id != null}
                                            disableClearable
                                            noOptionsText="Nincs ilyen projekt"
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onChange={(event, newValue) => onChangeSelectItem(newValue, "project")}
                                            options={projects}
                                            getOptionLabel={(project) => project.name}
                                            style={{ marginTop: "16px" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Projekt" required />
                                            )}
                                        />
                                    </Grid>

                                    {createInputField("description", "Feladat Leírása", projectTask.description, onChange, false, false, false, "text")}

                                    <Grid item lg={5} md={10} xs={20}>
                                        <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
                                            <InputLabel id="priority-label">Prioritás</InputLabel>
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                labelId="priority-label"
                                                name="priority"
                                                label="Prioritás"
                                                value={projectTask.priority}
                                                defaultValue={"NORMAL"}
                                                onChange={onChange}
                                                sx={{ textAlign: "left" }}
                                            >
                                                <MenuItem value={"EXTRA_IMPORTANT"}>Extra fontos</MenuItem>
                                                <MenuItem value={"IMPORTANT"}>Fontos</MenuItem>
                                                <MenuItem value={"NORMAL"}>Normál</MenuItem>
                                                <MenuItem value={"NOT_IMPORTANT"}>Nem fontos</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={5} md={10} xs={20}>
                                        <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
                                            <InputLabel id="status-label">Státusz</InputLabel>
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                labelId="status-label"
                                                name="status"
                                                label="Státusz"
                                                value={projectTask.status}
                                                defaultValue={"NOT_STARTED"}
                                                onChange={onChange}
                                                sx={{ textAlign: "left" }}
                                            >
                                                <MenuItem value={"NOT_STARTED"}>Nincs elkezdve</MenuItem>
                                                <MenuItem value={"IN_PROGRESS"}>Folyamatban</MenuItem>
                                                <MenuItem value={"BLOCKED"}>Elakadva</MenuItem>
                                                <MenuItem value={"OVERDUE"}>Csúszásban</MenuItem>
                                                <MenuItem value={"DONE"}>Befejezve</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xl={5} lg={10} md={10} xs={20}>
                                        <Autocomplete
                                            value={projectTask.taskType || null}
                                            margin="normal"
                                            disableClearable
                                            onChange={(event, newValue) => onChangeSelectItem(newValue, "taskType")}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            options={taskTypes}
                                            getOptionLabel={(taskType) => taskType.name}
                                            style={{ marginTop: "16px" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Feladat Típus" required />
                                            )}
                                        />
                                    </Grid>

                                    {id == null &&
                                        <React.Fragment style={{ display: "none" }}>
                                            <Grid item xs={20}>
                                                <Divider />
                                            </Grid>

                                            {createInputField("subTaskDescription", "Alfeladat Leírása", projectTask.subTaskDescription, onChange, false, false, false, "text")}
                                            {createInputField("condition", "Feltétel", projectTask.condition, onChange)}
                                            {createInputField("progressLevel", "Készültségi Szint", projectTask.progressLevel === null ? null : projectTask.progressLevel?.toString(), onChange, false, false, false, "number")}
                                            {createInputField("weight", "Feladat Súly Hányadosa", projectTask.weight === null ? null : projectTask.weight?.toString(), onChange, false, false, false, "number")}

                                            <Grid item xl={5} lg={10} md={10} xs={20}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DateTimePicker label="Határidő" value={projectTask.deadline} sx={{ width: "100%", marginTop: "16px" }} onChange={(newValue) => setDate(newValue, "deadline")} />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xl={5} lg={10} md={10} xs={20}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DateTimePicker label="Tervezett Kezdés" value={projectTask.scheduleDateStart} sx={{ width: "100%", marginTop: "16px" }} onChange={(newValue) => setDate(newValue, "scheduleDateStart")} />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xl={5} lg={10} md={10} xs={20}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DateTimePicker label="Tervezett Befejezés" value={projectTask.scheduleDateEnd} sx={{ width: "100%", marginTop: "16px" }} onChange={(newValue) => setDate(newValue, "scheduleDateEnd")} />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xl={5} lg={10} md={10} xs={20}>
                                                <Autocomplete
                                                    value={projectTask.vehicle || null}
                                                    margin="normal"
                                                    disableClearable
                                                    onChange={(event, newValue) => onChangeSelectItem(newValue, "vehicle")}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    options={vehicles}
                                                    getOptionLabel={(vehicle) => vehicle.licensePlate}
                                                    style={{ marginTop: "16px" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Jármű" />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xl={5} lg={10} md={10} xs={20}>
                                                <FormControl sx={{ m: 1, minWidth: 120, marginTop: "16px", marginLeft: "0px" }} fullWidth>
                                                    <InputLabel id="users-label">Dolgozók</InputLabel>
                                                    <Select
                                                        labelId="users-label"
                                                        multiple
                                                        value={projectTask.users}
                                                        onChange={onUsersChange}
                                                        label="Dolgozók"
                                                        variant="outlined"
                                                        input={<OutlinedInput id="select-multiple-chip" label="Dolgozók" />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.0, margin: "-5px" }}>
                                                                {selected.map((value) => (
                                                                    <Chip key={value.id} label={value.name} />
                                                                ))}
                                                            </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {users.map((user) => (
                                                            <MenuItem
                                                                key={user.id}
                                                                value={user}
                                                                style={getStyles(user.name, projectTask.users)}
                                                            >
                                                                {user.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </React.Fragment>}
                                    {id !== undefined &&
                                        <>
                                            <Grid item lg={20} md={20} xs={20}>
                                                <Divider />
                                            </Grid>

                                            {createInputField("createdAt", "Létrehozva", projectTask.createdAt, onChange, false, false, true)}
                                            {createInputField("updatedAt", "Módosítva", projectTask.updatedAt, onChange, false, false, true)}
                                            <Grid item lg={10} xs={20}></Grid>
                                        </>

                                    }

                                    {message &&
                                        <Grid item lg={20} xs={20}>
                                            <Alert severity="error" variant="standard" style={{ color: "red" }}>{message}</Alert>
                                        </Grid>
                                    }
                                </>}

                            <Grid item lg={20} md={10} xs={2}></Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                <Button type="button" fullWidth variant="contained" color="error" onClick={() => navigate("/dashboard/project-tasks")}>
                                    Vissza
                                </Button>
                            </Grid>

                            <Grid item lg={14} md={5} xs={20}></Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                {id != null && <Button type="button" fullWidth variant="contained" color="primary" onClick={() => handleCopyClickOpen()}>
                                    Mentés Újként
                                </Button>}
                            </Grid>

                            <Grid item lg={2} md={5} xs={9}>
                                {waitingForServer ? <CircularProgress sx={{ margin: "-5px" }} /> : <Button type="submit" fullWidth variant="contained" color="success">
                                    Mentés
                                </Button>}
                            </Grid>

                        </Grid>
                    </form>
                </div>

                {id &&
                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: "16px" }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} style={{ fontWeight: 700, background: "lightgray" }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        <TableCell key="actions" style={{ fontWeight: 700, background: "lightgray", minWidth: "135px" }} align='right' padding='none'>
                                            <Button variant='contained' color='success' sx={{ minWidth: "0px", padding: "0px", marginRight: "10px" }} onClick={() => navigate("/dashboard/project-tasks/" + projectTask.id + "/sub-tasks/create")}>
                                                <Add fontSize="large" />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectTask.subTasks == null ? <TableRow sx={{ textAlign: "center" }}><TableCell colSpan={16} sx={{ padding: "100px", textAlign: "center" }}><CircularProgress /></TableCell></TableRow> :
                                        projectTask.subTasks.map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {columns.map((column) => {
                                                        let value = column.subId != null ? (row[column.id] == null ? null : row[column.id][column.subId]) : row[column.id];
                                                        if (column.id === 'users')
                                                            value = row[column.id].map(user => user[column.subId]).join(", ")
                                                        else if (column.id === 'progressLevel')
                                                            value = <PercentageProgressBar fullWidth percentage={row[column.id]} narrow readonly color={"rgba(0,255,0,0.4)"} align='center' />
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.id === 'project' ? <Link sx={{ fontWeight: "bold", textDecoration: "none" }} align="left" component="button" variant="body2" onClick={() => navigate("/dashboard/projects/" + row.project.id)}>{value}</Link> : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                    <TableCell key="actions" padding='none' align='right'>
                                                        <Button color='primary' variant='contained' padding='30px'
                                                            sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }} onClick={() => navigate("/dashboard/project-tasks/" + projectTask.id + "/sub-tasks/" + row.id)}>
                                                            <Edit />
                                                        </Button>
                                                        <Button color='error' variant='contained' width='16px' sx={{ minWidth: "0px", padding: "5px", marginRight: "10px" }}
                                                            onClick={() => handleClickOpen(row)}>
                                                            <Delete />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>}

            </Container>

            <ConfirmationDialog
                title={"Biztosan törölni szeretnéd az alábbi feladatot?"}
                content={
                    <>
                        {dialogState.object?.description}<br />
                    </>
                }
                open={dialogState.open}
                onClose={handleClose}
                onConfirm={() => deleteSubTask(dialogState)}
            />

            <Dialog open={copyDialogState.open} onClose={handleCopyClose} maxWidth="false">
                <DialogTitle sx={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between', bgcolor: '#1976d2;;', color: '#fff' }}>
                    <Typography variant="h6">Válaszd ki melyik projekt alá szeretnéd másolni a feladatot!</Typography>
                </DialogTitle>
                <DialogContent sx={{ bgcolor: '#ededed' }}>
                    <DialogContentText sx={{ mb: 1, paddingTop: "15px", color: "black" }}>

                        <Autocomplete
                            value={copyDialogState.project || null}
                            margin="normal"
                            label="Cél Projekt"
                            autoHighlight
                            autoSelect
                            disableClearable
                            noOptionsText="Nincs ilyen projekt"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => changeCopyProjectTarget(newValue)}
                            options={projects}
                            getOptionLabel={(project) => project.name}
                            style={{ marginTop: "16px" }}
                            renderInput={(params) => (
                                <TextField {...params} label="Cél Projekt" required />
                            )}
                        />

                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2, bgcolor: '#ededed', justifyContent: 'space-between' }}>
                    <Button
                        onClick={() => {
                            handleCopyClose();
                            copyProjectTask(copyDialogState.project.id);
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {"Másolás"}
                    </Button>
                    <Button
                        onClick={handleCopyClose}
                        color="primary"
                        variant="outlined"
                        autoFocus
                    >
                        Mégse
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

function createInputField(name, label, value, onChange, required = false, autoFocus = false, disabled = false, type = "text") {
    return (<Grid item lg={5} md={10} xs={20}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type={type}
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value || (type === 'date' ? "-1" : "")}
            disabled={disabled}
        />
    </Grid>)
}