import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import payrollService from '../../services/PayrollService';
import { Button } from '@mui/material';

const dayTypes = ["WEEKDAY", "SATURDAY", "SUNDAY"]
const workTypes = ["WORK_NORMAL", "WORK_OVERTIME", "DRIVE_NORMAL", "DRIVE_OVERTIME", "PASSENGER_NORMAL", "PASSENGER_OVERTIME"]


export default function Payroll() {
    const [userId] = React.useState(window.location.pathname.split('/')[3])
    const [year] = React.useState(window.location.pathname.split('/')[5])
    const [month] = React.useState(window.location.pathname.split('/')[7])

    const [payroll, setPayroll] = React.useState(undefined);

    useEffect(() => {
        payrollService.getPayrollOfUser(userId, year, month,
            (response, error) => {
                if (response) {
                    setPayroll(response);
                }
            }
        );
    }, [userId, year, month]);

    const navigate = useNavigate();

    if (payroll === undefined) return (<></>)

    const boldCellStyle = { fontWeight: 700, background: "darkgray", padding: "6px" }
    const normalCellStyle = { fontWeight: 500, background: "lightgray", width: "200px", padding: "6px" }

    return (
        <>
            <Paper>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" border="1" padding='none'>
                        <TableHead >
                            <TableRow>
                                <TableCell style={{ ...boldCellStyle, width: "100px" }} align='center' rowSpan={3} padding='none'>{payroll.name}<br />{payroll.from}<br />{payroll.to}</TableCell>
                                <TableCell style={boldCellStyle} align='center' colSpan={2} rowSpan={2} padding='none'>
                                    Munkaórák
                                </TableCell>
                                <TableCell style={boldCellStyle} align='center' colSpan={4} padding='none'>
                                    Utazás
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell style={boldCellStyle} align='center' colSpan={2} padding='none'>
                                    Vezető
                                </TableCell>
                                <TableCell style={boldCellStyle} align='center' colSpan={2} padding='none'>
                                    Utas
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell style={normalCellStyle} align='center' padding='none'>
                                    Munkaidőben
                                </TableCell>
                                <TableCell style={normalCellStyle} align='center' padding='none'>
                                    Túlóra
                                </TableCell>
                                <TableCell style={normalCellStyle} align='center' padding='none'>
                                    Munkaidőben
                                </TableCell>
                                <TableCell style={normalCellStyle} align='center' padding='none'>
                                    Túlóra
                                </TableCell>
                                <TableCell style={normalCellStyle} align='center' padding='none'>
                                    Munkaidőben
                                </TableCell>
                                <TableCell style={normalCellStyle} align='center' padding='none'>
                                    Túlóra
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dayTypes.map(dayType =>
                                <>
                                    <TableRow >
                                        <TableCell style={boldCellStyle} align='center' rowSpan={2}>
                                            {(() => {
                                                switch (dayType) {
                                                    case "WEEKDAY": return "Hétköznap";
                                                    case "SATURDAY": return "Szombat";
                                                    case "SUNDAY": return "Vasárnap";
                                                    default: console.log("No such day type!")
                                                }
                                            })()}
                                        </TableCell>
                                        {workTypes.map(workType =>

                                            <TableCell align='center' style={{ padding: "8px" }}>
                                                {formatTime(payroll.payrollValues[dayType][workType].duration)}
                                            </TableCell>

                                        )}
                                    </TableRow>
                                    <TableRow >
                                        {workTypes.map(workType =>
                                            <TableCell align='center' style={{ padding: "8px" }}>
                                                {formatPayment(payroll.payrollValues[dayType][workType].payment)}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </>)
                            }
                            <TableRow>
                                <TableCell style={boldCellStyle} align='center' colSpan={2}>
                                </TableCell>
                                <TableCell style={boldCellStyle} align='center'>
                                    Bonus-Malus
                                </TableCell>
                                <TableCell style={boldCellStyle} align='center' colSpan={2}>
                                    Munkavállalói Értékek
                                </TableCell>
                                <TableCell style={boldCellStyle} align='center' colSpan={2}>
                                    Rögzített Értékek
                                </TableCell>
                            </TableRow>
                            {payroll.salaryTemplateType === 'EXTERNAL_SUBCONTRACTOR' ?
                                <TableRow>
                                    <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                        Üzemanyag Pótlék
                                    </TableCell>
                                    <TableCell align='center'>
                                        {formatPayment(payroll.distanceSalaryAmount)}
                                    </TableCell>
                                    <TableCell style={boldCellStyle} align='right'>
                                        Megtett Távolság
                                    </TableCell>
                                    <TableCell align='center'>
                                        {payroll.totalDistance + " km"}
                                    </TableCell>
                                    <TableCell style={boldCellStyle} align='right'>
                                        Kilométer Díj
                                    </TableCell>
                                    <TableCell align='center'>
                                        {formatPayment(payroll.userPaymentVariables.kilometerCost)}
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                        Mozgóbér Összege
                                    </TableCell>
                                    <TableCell align='center'>
                                        {formatPayment(payroll.mobileSalaryAmount)}
                                    </TableCell>
                                    <TableCell style={boldCellStyle} align='right'>
                                        Mozgóbér
                                    </TableCell>
                                    <TableCell align='center'>
                                        {formatPercentage(payroll.mobileSalaryPercentage)}
                                    </TableCell>
                                    <TableCell style={boldCellStyle} align='right'>
                                        Mozgóbér maximum
                                    </TableCell>
                                    <TableCell align='center'>
                                        {formatPercentage(payroll.globalPaymentVariables.maximumMobileSalaryPercentage)}
                                    </TableCell>
                                </TableRow>
                            }

                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Szállás Pótlék
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.accommodationSalaryAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Szállás Éjszakák
                                </TableCell>
                                <TableCell align='center' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                    {payroll.accommodationCount}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Szállás Pótlék Éjszakánként
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.globalPaymentVariables.accommodationSalaryPerNight)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Programozás Pótlék
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.programmingAllowanceAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Programozás
                                </TableCell>
                                <TableCell align='center'>
                                    {formatTime(payroll.programmingDuration)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Programozás Óránkénti Pótlék
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.globalPaymentVariables.programmingHourlyRate)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Csoportvezetői Pótlék
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.teamLeaderAllowanceAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Csoportvezetés
                                </TableCell>
                                <TableCell align='center'>
                                    {formatTime(payroll.teamLeaderDuration)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Csoportvezetés Óránkénti Pótlék
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.globalPaymentVariables.teamLeaderHourlyRate)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Jelenléti Bónusz
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.presenceBonusAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Jelenléti Bónuszra Jogosult
                                </TableCell>
                                <TableCell align='center' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                    {payroll.presenceBonusAmount > 0 ? "Igen" : "Nem"}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Jelenléti Bónusz
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.globalPaymentVariables.presenceBonusAmount)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Ebédszünetek
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.lunchBreakAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Ebédszünetek Száma
                                </TableCell>
                                <TableCell align='center' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                    {payroll.lunchBreakCount}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Ebédszünet hossza
                                </TableCell>
                                <TableCell align='center'>
                                    {formatTime(payroll.globalPaymentVariables.lunchBreakLengthInMinutes * 60)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Késés
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.latenessFineAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Késések Száma
                                </TableCell>
                                <TableCell align='center' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                    {payroll.latenessCount}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Késésenkénti Levonás
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.globalPaymentVariables.latenessFineAmount)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Szabadságok Utáni Fizetés
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.vacationAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Szabadságok Száma
                                </TableCell>
                                <TableCell align='center' >
                                    <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{payroll.vacationCount + " (" + payroll.availableVacationDays + " "}</span>
                                    {" maradt"}
                                    <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{")"}</span>

                                </TableCell>
                                <TableCell style={boldCellStyle} align='center' colSpan={2}>Összesítés</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Táppénz Utáni Fizetés
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.sickPayAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Táppénzes Napok Száma
                                </TableCell>
                                <TableCell align='center' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                    {payroll.sickPayCount}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Összes Óra
                                </TableCell>
                                <TableCell align='center' style={{ fontWeight: 'bold' }}>
                                    {formatTime(payroll.totalHours)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Fizetett Távollét Utáni Fizetés
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.payedLeaveAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Fizetett Távolléti Napok Száma
                                </TableCell>
                                <TableCell align='center' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                    {payroll.payedLeaveCount}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Effektív Órabér
                                </TableCell>
                                <TableCell align='center' style={{ fontWeight: 'bold' }}>
                                    {formatPayment(payroll.effectiveHourlyRate)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    SZJA Összege
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.szjaSalaryAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Havi SZJA Összege
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.userPaymentVariables.monthlySzjaSalaryAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Effektív Munka Órabér
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.effectiveBasicHourlyRate)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={boldCellStyle} align='right' colSpan={2}>
                                    Kompenzáció
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.compensationAmount)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Órabér
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.userPaymentVariables.hourlyRate)}
                                </TableCell>
                                <TableCell style={boldCellStyle} align='right'>
                                    Átutalt Bér
                                </TableCell>
                                <TableCell align='center'>
                                    {formatPayment(payroll.userPaymentVariables.transferredSalaryAmount)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ ...boldCellStyle, border: "3px solid black", borderRight: "none" }} align='right' colSpan={2}>
                                    Végösszeg
                                </TableCell>
                                <TableCell align='center' style={{ border: "3px solid black", borderRight: "none", borderLeft: "1px inset" }}>
                                    {formatPayment(payroll.totalSalaryAmount)}
                                </TableCell>
                                <TableCell style={{ ...boldCellStyle, border: "3px solid black", borderLeft: "none", borderRight: "none" }} align='right'>
                                    Ledolgozott Munkanapok
                                </TableCell>
                                <TableCell align='center' style={{ border: "3px solid black", borderRight: "none", borderLeft: "1px inset" }}>
                                    <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{payroll.workDays.length}</span>
                                </TableCell>
                                <TableCell style={{ ...boldCellStyle, border: "3px solid black", borderLeft: "none", borderRight: "none" }} align='right'>
                                    Készpénz
                                </TableCell>
                                <TableCell align='center' style={{ border: "3px solid black", borderRight: "3px solid black", borderLeft: "1px inset" }}>
                                    {formatPayment(payroll.cashSalaryAmount)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Button type="button" variant="contained" color="error" onClick={() => navigate(-1)} style={{ width: "150px", marginTop: "10px" }}>
                Vissza
            </Button>
        </>
    );
}

function formatTime(seconds) {
    if (seconds === 0) return '-'
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedTime = (
        <span>
            {hours > 0 && <>
                <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{hours}</span>{' óra '}
            </>}
            {minutes > 0 && (
                <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{minutes}</span>
            )}
            {minutes > 0 && ' perc'}
        </span>
    );
    return formattedTime;
}

function formatPayment(payment) {
    if (payment === null || payment === 0) return "-"
    const value = payment.toLocaleString()
    return <>
        <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{value}</span>{' Ft'}</>
}

function formatPercentage(percentage) {
    return <>
        <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>{percentage}</span>{' %'}</>
}